
  /* eslint @typescript-eslint/no-unused-vars: 0 */
type P = string | number
type S = string
export default {
agency_link_box_desc: `Let your campaign be created with our UGC creatives by our partner agency.`,
agency_link_box_header: `Agency Service`,
agency_link_box_linkbox: `Get Started Now`,
agency_link_box_linkbox_url: `https://ugc.by.stylink.com/partneragentur`,
back: `Back`,
brand_creation_contact_email: `Email address*`,
brand_creation_contact_firstname: `First Name*`,
brand_creation_contact_phone: `Phone number*`,
brand_creation_contact_surname: `Last Name*`,
brand_creation_contact_title: `Contact Person`,
brand_creation_country_austria: `Austria`,
brand_creation_country_england: `United Kingdom`,
brand_creation_country_germany: `Germany`,
brand_creation_country_label: `Country`,
brand_creation_country_switzerland: `Switzerland`,
brand_creation_create_agency: `CREATE AGENCY`,
brand_creation_create_brand: `CREATE BRAND`,
brand_creation_error: `Oh no, you forgot something!`,
brand_creation_info_agency_logo: `Agency logo`,
brand_creation_info_agency_name: `Agency name*`,
brand_creation_info_agency_name_question: `How is your agency called?`,
brand_creation_info_brand_audio: `Brand Pronunciation`,
brand_creation_info_brand_logo: `Brand logo`,
brand_creation_info_brand_name: `Brand name*`,
brand_creation_info_brand_name_question: `How is your brand called?`,
brand_creation_info_country_question: `In which country is your company based?`,
brand_creation_info_industry: `Industry`,
brand_creation_info_industry_option_accessories: `Accessories (Jewellery, watches etc.)`,
brand_creation_info_industry_option_agency: `Advertising agency`,
brand_creation_info_industry_option_apparel: `Fashion & Accessoires`,
brand_creation_info_industry_option_beauty: `Beauty & Make-Up`,
brand_creation_info_industry_option_beverages: `Beverages`,
brand_creation_info_industry_option_books: `Books & Stationery`,
brand_creation_info_industry_option_business: `Business Services`,
brand_creation_info_industry_option_digital_products: `Digital Products & Apps`,
brand_creation_info_industry_option_diverse: `Diverse`,
brand_creation_info_industry_option_education: `Education`,
brand_creation_info_industry_option_entertainment: `Entertainment`,
brand_creation_info_industry_option_finance: `Finance & Insurance`,
brand_creation_info_industry_option_financial: `Financial Services`,
brand_creation_info_industry_option_fitness: `Fitness`,
brand_creation_info_industry_option_food: `Food`,
brand_creation_info_industry_option_games: `Gaming`,
brand_creation_info_industry_option_health: `Health & Fitness`,
brand_creation_info_industry_option_interior_diy: `Interior & DIY`,
brand_creation_info_industry_option_jobs: `Jobs`,
brand_creation_info_industry_option_luxury: `Luxury`,
brand_creation_info_industry_option_mobile: `Mobile apps`,
brand_creation_info_industry_option_mommy_kids: `Mommy & Kids`,
brand_creation_info_industry_option_other: `Other`,
brand_creation_info_industry_option_pets: `Pet supplies`,
brand_creation_info_industry_option_technik: `Technology`,
brand_creation_info_industry_option_travel: `Travel`,
brand_creation_section_agency_info: `Agency info`,
brand_creation_section_brand_info: `Brand info`,
brand_creation_section_contact: `Contact Person`,
brand_creation_section_users: `Employees`,
brand_creation_upload_logo_error: `Oops! Your logo upload has failed but your brand will still be created. Please reach out to us via ugc@stylink.com so that we can add your missing logo.`,
brand_creation_users_body: `You can assign the following roles to other users:
          <br /> <br />
          <b> Admin: </b> Can edit brand settings and campaigns.
          <br />
          <b> Marketer: </b> Can edit campaigns.
          <br />
          <b> Analyst: </b> Can view campaigns.
          <br />
          <b> Accountant: </b> Can only view invoice overviews.`,
brand_creation_users_email_label: `Email address`,
brand_creation_users_invite: `Invite`,
brand_creation_users_role_accountant: `Accounting`,
brand_creation_users_role_admin: `Admin`,
brand_creation_users_role_analyst: `Analyst`,
brand_creation_users_role_label: `Role`,
brand_creation_users_role_marketer: `Marketer`,
brand_creation_users_title: `More users`,
brand_creation_yup_brand_name: `Brand name must be stated.`,
brand_creation_yup_email: `Email must be stated.`,
brand_creation_yup_email1: `Invalid email address.`,
brand_creation_yup_email2: `Email must be stated.`,
brand_creation_yup_firstname: `First name must be stated.`,
brand_creation_yup_industry: `Industry must be stated.`,
brand_creation_yup_mobile: `Phone number must be stated.`,
brand_creation_yup_mobile1: `Invalid phone number.`,
brand_creation_yup_role: `Role must be stated.`,
brand_creation_yup_surname: `Last name must be stated.`,
brand_invoices_credit: `Credit note`,
brand_invoices_debit: `Withdrawal`,
brand_invoices_download: `Download invoice`,
brand_invoices_download_error: `Please reach out to our support.`,
brand_invoices_empty: `You have no invoices yet.`,
brand_invoices_error: `Oops! An unknown error has occurred.`,
brand_invoices_missing_date: `Date must be stated.`,
brand_invoices_no_auth: `You have no authorisation to access this area.`,
brand_invoices_number: `Invoice number`,
brand_invoices_table_date: `Date`,
brand_invoices_table_sum: `Total`,
brand_invoices_table_type: `Reference`,
brand_payment_balance_current: `Current balance:`,
brand_payment_creditcard_title: `Credit card`,
brand_payment_credit_title: `Balance`,
brand_payment_method_choose: `Choose your payment option.`,
brand_payment_method_choose_error: `Oops! An error has occurred.`,
brand_payment_method_no_role: `You have no authorisation to change the payment option.`,
brand_payment_method_saved: (last4: P) => `Your chosen payment option ends with the digits ${last4 as S}.` as const,
brand_payment_method_saved_nothing: `You have not specified your payment option yet.`,
brand_payment_method_title: `Payment option`,
brand_payment_save: `Save payment option`,
brand_payment_sepa_agreement: `By signing this mandate form, you authorise (A) stylink Social Media GmbH and Stripe to withdraw payments from your account. You further mandate (B) your bank to debit your account in accordance with the instructions from stylink Social Media GmbH. Please note: You can request a refund of the debited amount within eight weeks, starting from the debit date. The conditions agreed upon with your bank apply.`,
brand_payment_sepa_title: `SEPA`,
brand_settings_nav_invoices: `Invoice`,
brand_settings_nav_payment: `Payment`,
brand_settings_nav_settings: `Settings`,
brand_settings_nav_users: `Users`,
brand_users_campaign_subtitle: `Brand management`,
brand_users_campaign_title: `Campaign`,
brand_users_email_title: `Email address`,
brand_users_error: `Oops! An error has occurred. Please try again.`,
brand_users_info: `You can assign the following roles to other users:
          <br /> <br />
          <b> Admin: </b> Can edit brand settings and campaigns.
          <br />
          <b> Marketer: </b> can edit campaigns.
          <br />
          <b> Analyst: </b> can view campaigns.
          <br />
          <b> Accountant: </b> Can only view invoice overviews.`,
brand_users_insert: `Add user`,
brand_users_insert_admin_only: `Only admins can add or remove users.`,
brand_users_insert_invalid_email: `No valid email address.`,
brand_users_invite: `Invite`,
brand_users_table_email: `Email`,
brand_users_table_role: `Role`,
campaign_creation_age_label: `Age (multiple choice)`,
campaign_creation_budget_creator: (number: P) => `${number as S} content creator` as const,
campaign_creation_budget_creators: (number: P) => `${number as S} content creators` as const,
campaign_creation_budget_info1: `Your order is delivered by`,
campaign_creation_budget_info2: `.`,
campaign_creation_budget_question: `How many videos do you want to request?`,
campaign_creation_budget_tip: `We recommend having the same video concept developed by multiple creators, so you can choose from the best content.`,
campaign_creation_budget_video_count: `Amount of videos`,
campaign_creation_budget_video_count_tip: `Work with at least 3-5 creators per ad campaign in order to achieve the best results.`,
campaign_creation_budget_videos_label: `Videos`,
campaign_creation_content_ctas_add_button: (c: P,i: P) => `Add alternative CTA (${c as S}${i as S})` as const,
campaign_creation_content_ctas_placeh: (i: P) => `Alternative CTA ${i as S}` as const,
campaign_creation_content_ctas_placeholder1: `You can boost your purchase recommendation by providing a discount code.`,
campaign_creation_content_ctas_title: `End / Call-to-Action (Optional)`,
campaign_creation_content_hooks_add_button: (c: P,i: P) => `Add alternative hook (${c as S}${i as S})` as const,
campaign_creation_content_hooks_placeh: (i: P) => `Alternative hook ${i as S}` as const,
campaign_creation_content_hooks_placeholder1: `Start your video in selfie mode with a warm greeting to instantly spread positive vibes.`,
campaign_creation_content_hooks_title: `Intro / Hook (optional)`,
campaign_creation_content_mentions_desc: `Define here which information has to be mentioned by the creators.`,
campaign_creation_content_mentions_label: (i: P) => `Info ${i as S}` as const,
campaign_creation_content_mentions_title: `Additional info (optional)`,
campaign_creation_content_moodboard_body: `Upload up to six images or videos which should serve as an inspiration for the creators. Also feel free define how you would like your subtitles to look like.`,
campaign_creation_content_moodboard_body1: `Show the creators how you want your videos to look like.`,
campaign_creation_content_moodboard_body2: `Upload up to six images or videos which should serve as an inspiration for the creators.`,
campaign_creation_content_moodboard_dont_use: `Do not use the examples`,
campaign_creation_content_moodboard_title: `Example videos/ images (optional)`,
campaign_creation_content_moodboard_use: `Use examples`,
campaign_creation_content_nano_storyboard_desc: `An expert from our team creates a storyboard for the creators.`,
campaign_creation_content_nano_storyboard_label: `stylink UGC storyboard`,
campaign_creation_content_scene_placeh: `Scenes`,
campaign_creation_content_scenes_desc: `Describe in individual scenes how the video should be structured, e.g. environment, choice of words, tonality, scenes (essential information for creators).`,
campaign_creation_content_scenes_placeholder1: `Scene 1: [desired perspective, environmental information] 
‘Insert literal speech in inverted commas’ 

Scene 2: [Instructions for creators] 
‘Insert literal speech in inverted commas’ 
 
Scene 3: [Instructions for creators] 
‘Insert literal speech in inverted commas’ 
 
If necessary, describe further scenes and add important information.`,
campaign_creation_content_scenes_title: `Scenes`,
campaign_creation_content_self_service_desc: `You set the scenes and further inspiration for the creators yourself.`,
campaign_creation_content_self_service_label: `Self service`,
campaign_creation_create: `CHECKOUT`,
campaign_creation_disclaimer_body: `Disclaimer: If there are delays on your part, this will also affect the delivery date.`,
campaign_creation_disclaimer_contact_check_label: `I agree to not contact the creators outside of this platform.`,
campaign_creation_disclaimer_info_check_label: `I acknowledge this information.`,
campaign_creation_disclaimer_no_payment_check: `Disclaimer: Don't create invoice. Invoice has already been created outside of stripe (admin).`,
campaign_creation_disclaimer_title: `Estimated delivery date`,
campaign_creation_error: `Oh no, you forgot something!`,
campaign_creation_format_title: `Video format`,
campaign_creation_gender_any: `All genders`,
campaign_creation_gender_diverse: `Diverse`,
campaign_creation_gender_female: `Female`,
campaign_creation_gender_label: `Gender`,
campaign_creation_gender_male: `Male`,
campaign_creation_general_campaign_image_label: `Product image`,
campaign_creation_general_product_desc_label: `Please describe your product briefly.*`,
campaign_creation_general_product_info: `Product information`,
campaign_creation_general_product_info_body: `Describe which product should be advertised by the creators.`,
campaign_creation_general_product_link_label: `Link to the product/website.*`,
campaign_creation_general_product_name_label: `Product name*`,
campaign_creation_general_product_price_disclaimer: `Please state your cost price instead of the selling price. `,
campaign_creation_general_product_price_label: (currency: P) => `Value in ${currency as S}*` as const,
campaign_creation_general_product_type: `Product type`,
campaign_creation_general_producttype_digital_desc: `For digital products (app, software, etc.)`,
campaign_creation_general_producttype_digital_title: `Digital`,
campaign_creation_general_producttype_physical_desc: `Send your product to the creators. They can keep it after completing the order.`,
campaign_creation_general_producttype_physical_title: `Physical`,
campaign_creation_invite_body: `Are there creators with whom you want to work again?`,
campaign_creation_invite_title: `Invite creator`,
campaign_creation_language_english: `English`,
campaign_creation_language_german: `German`,
campaign_creation_language_label: `Video language`,
campaign_creation_persist_body: `You still have an unfinished order stored. Do you want to load this data?`,
campaign_creation_persist_delete: `Delete`,
campaign_creation_persist_title: `Load stored data`,
campaign_creation_preferences: `Product preferences`,
campaign_creation_preferences_no: `The product is suitable for everyone.`,
campaign_creation_preferences_placeh: `Example: What clothing size do you wear?`,
campaign_creation_preferences_question: (i: P) => `Question ${i as S}` as const,
campaign_creation_preferences_tooltip: `Do you need certain information about the creators, such as their shoe size, to ship them the right product? Add your questions here to collect any necessary information.`,
campaign_creation_preferences_yes: `I need further information (e.g. shoe size) from the creators.`,
campaign_creation_requirements: `Which creators should realise your video?`,
campaign_creation_section_brand: `Brand info`,
campaign_creation_section_budget: `Amount`,
campaign_creation_section_content: `Content`,
campaign_creation_section_invite: `Invites`,
campaign_creation_section_product: `Product`,
campaign_creation_section_requirements: `Requirements`,
campaign_creation_section_video_type: `Video type`,
campaignCreationTimelineApplications: (endDate: P) => `Select applicants\nby ${endDate as S}` as const,
campaignCreationTimelineDelivery: (endDate: P) => `Send product\nbis ${endDate as S}` as const,
campaignCreationTimelineFeedback: (endDate: P) => `Receive video\nby ${endDate as S}` as const,
campaignCreationTimelineVideos: (endDate: P) => `Give feedback (optional)\nby ${endDate as S}` as const,
campaign_creation_update: `SAVE UPDATE`,
campaign_creation_upload_brand_audio_error: `Oops! Your audio could not be uploaded, but your brand will still be created. Please contact us at ugc@stylink.com so that we can add your image.`,
campaign_creation_upload_brand_logo_error: `Oops! Your picture upload has failed but your brand will still be created. Please reach out to us via ugc@stylink.com so that we can add your missing picture.`,
campaign_creation_upload_campaign_image_error: `Oops! Your picture upload has failed but your campaign will still be created. Please reach out to us via ugc@stylink.com so that we can add your missing picture.`,
campaign_creation_upload_moodboard_error: `Error uploading the example.`,
campaign_creation_video_duration_15: `10 - 15 seconds`,
campaign_creation_video_duration_30: `25 - 30 seconds`,
campaign_creation_video_duration_60: `60 seconds`,
campaign_creation_video_duration_price: (c: P,price: P) => `${c as S} ${price as S} / sec` as const,
campaign_creation_video_duration_title: `Video duration`,
campaign_creation_video_features_info: `These additional services are delivered directly via our team.`,
campaign_creation_video_features_title: `Additional services (optional)`,
campaign_creation_video_format_fullscreen: `Fullscreen (horizontal)`,
campaign_creation_video_format_horizontal: `Fullscreen (horizontal)`,
campaign_creation_video_format_horizontal_platform: `YouTube`,
campaign_creation_video_format_vertical: `Vertical`,
campaign_creation_video_format_vertical_platform: `Instagram | TikTok`,
campaign_creation_video_type_ad_desc: `Ideal for social media ads.`,
campaign_creation_video_type_ad_title: `UGC video`,
campaign_creation_video_type_example_title: `Example video`,
campaign_creation_video_type_premium_desc: `Professional UGC videos from experienced creators with existing expertise in lighting, sound quality, and more.`,
campaign_creation_video_type_premium_title: `UGC premium video`,
campaign_creation_video_type_title: `Video type`,
campaign_creation_yup_age: `Age must be stated.`,
campaign_creation_yup_brand_logo: `Logo must be uploaded.`,
campaign_creation_yup_campaign_image: `Product image must be uploaded.`,
campaign_creation_yup_contact_check: `Please confirm that you will not get in touch with the creators outside of this platform. `,
campaign_creation_yup_country: `Country must be stated.`,
campaign_creation_yup_gender: `Gender must be stated.`,
campaign_creation_yup_hooks_ctas_limit: `A maximum of 200 characters is possible.`,
campaign_creation_yup_hooks_product_urls_limit: `Maximum 200 characters possible`,
campaign_creation_yup_info_check: `Please confirm that you have acknowledged the information.`,
campaign_creation_yup_language: `Language must be stated.`,
campaign_creation_yup_link1: `Invalid URL`,
campaign_creation_yup_link2: `Website must be stated.`,
campaign_creation_yup_locale: `The type of shipment must be stated.`,
campaignCreationYupMetionsLimit: `Maximum 120 characters possible`,
campaign_creation_yup_name: `Name must be stated.`,
campaign_creation_yup_number_of_slots1: `You must at least select one video.`,
campaign_creation_yup_number_of_slots2: `Number of videos must be stated.`,
campaign_creation_yup_number_of_slots3: `A maximum of 10 videos is possible.`,
campaign_creation_yup_price1: `Price must be stated as a number.`,
campaign_creation_yup_price2: `Price must be higher than 0€.`,
campaign_creation_yup_price3: `Price must be stated.`,
campaign_creation_yup_product_desc: `Description must be stated.`,
campaign_creation_yup_product_type: `Product type must be stated.`,
campaign_creation_yup_scenes1: `Scenes must be stated.`,
campaign_creation_yup_scenes2: `Select at least one scene.`,
campaign_creation_yup_video_duration: `Video duration must be stated.`,
campaign_creation_yup_video_format: `Video format must be stated.`,
campaign_creation_yup_video_type: `Video type must be stated.`,
campaign_order_photos_description: `Have creative images created in any popular format to go with your video to make your social media accounts, newsletters and online shop more appealing.`,
campaign_order_photos_question: `How many photos do you need? (24₤/ photo)`,
campaign_order_photos_text_field: `Describe the individual scenes of how the photos should be taken.`,
campaign_order_photos_title: `Order photos at the same time`,
campaign_overview_accept_influencer: `ACCEPT`,
campaign_overview_accept_influencer_error: `Oops! An error has occurred while accepting.`,
campaignOverviewApplicantsEmptyBody: `Where is everyone?`,
campaignOverviewApplicantsEmptyTitle: `You have no applicants for this campaign`,
campaign_overview_book_influencer: `BOOK`,
campaign_overview_check_empty_body: `There are no videos for your campaign yet.`,
campaign_overview_check_empty_done_body: `No new content pieces to review.`,
campaign_overview_check_empty_done_title: `All done!`,
campaign_overview_check_empty_title: `Oops! There are no content pieces yet`,
campaign_overview_check_give_feedback: `Feel free to add a feedback.`,
campaign_overview_check_info: `Booked additional services, such as subtitles, music and animations, are added by our team after accepting.`,
campaign_overview_content_being_edited: `Currently edited`,
campaign_overview_content_download: `Download`,
campaign_overview_content_duplicate_body: `Duplicate your order now to get more UGC videos.`,
campaign_overview_content_duplicate_button: `Duplicate order`,
campaign_overview_content_duplicate_title: `Get more videos`,
campaign_overview_content_empty_body: `You don't have finalised videos yet.`,
campaign_overview_content_empty_title: `No finalised videos`,
campaignOverviewContentGiveRatingBody: `Based on your feedback, we can show you suitable creators for future orders.`,
campaignOverviewContentGiveRatingButton: `Send rating`,
campaignOverviewContentGiveRatingPholder: `Let the creator know what they can do better.`,
campaignOverviewContentGiveRatingTitle: `Submit rating`,
campaign_overview_content_raw_download: `Download raw file`,
campaign_overview_content_upgraded_message: `Successfully booked!`,
campaign_overview_content_upgrade_label: `Book upgrade`,
campaign_overview_creators_address: `Address`,
campaign_overview_creators_being_edited: `Currently edited`,
campaign_overview_creators_check_content: `Check content`,
campaign_overview_creators_creator: `Creator`,
campaign_overview_creators_delete_button: `Remove`,
campaign_overview_creators_disclaimer: `If possible, state the email ugc@stylink.com with your shipment partner. That way we can prevent delivery issues.`,
campaign_overview_creators_empty_body: `You have no active creators yet.`,
campaign_overview_creators_empty_title: `No active creators`,
campaign_overview_creators_finished: `Completed`,
campaign_overview_creators_give_feedback: (endDate: P) => `Feel free to add feedback until ${endDate as S}.` as const,
campaign_overview_creators_mail: (brandName: P,firstname: P) => `mailto:ugc@stylink.com?subject=Problem in ${brandName as S} with stylink UGC ${firstname as S}` as const,
campaign_overview_creators_mail_button: `Report`,
campaign_overview_creators_make_2nd_content: (endDate: P) => `Creator reworks content until ${endDate as S}.` as const,
campaign_overview_creators_product_preferences: `Product preferences`,
campaign_overview_creators_send_until: (endDate: P) => `Send until ${endDate as S}.` as const,
campaign_overview_creators_shipping_button: `Shipment data`,
campaign_overview_creators_status: `Status`,
campaign_overview_creators_success: `All done!`,
campaign_overview_creators_title1: `Creator`,
campaign_overview_creators_title2: `Product shipped`,
campaign_overview_creators_title3: `Content created`,
campaign_overview_creators_todo: `To do`,
campaign_overview_creators_wait_for_feedback: `Content is being reviewed by our team.`,
campaign_overview_make_content: (endDate: P) => `Creator creates content until ${endDate as S}.` as const,
campaign_overview_no_more_codes: `There are no more voucher codes. Please add more before you accept new creators.`,
campaign_overview_no_more_slots: `Oops! You don't have any free videos left.`,
campaign_overview_onsent_error: `Oops! An error has occurred while sending.`,
campaign_overview_participant: `Creator:`,
campaign_overview_stats_body1: `Creators upload an application video promoting a product of their choice to show how they create content. Each booking is mandatory.`,
campaign_overview_stats_body2: (daysForApplying: P) => `Applications remain for ${daysForApplying as S} days.` as const,
campaign_overview_stats_title: `Please note:`,
campaignOverviewStepApplications: `Applications`,
campaignOverviewStepCheck: `Check Content`,
campaignOverviewStepContent: `My Content`,
campaignOverviewStepCreators: `My Creators`,
campaign_photo_label: `Photo`,
campaign_photos_label: `Photos`,
cancel: `Reject`,
change: `Change`,
choose_unboxing_style: `Submit rating`,
clear: `Okay!`,
common_saved: `Successfully saved!`,
copied: `Copied!`,
creators_status_deleted_firstupload: `Canceled`,
creators_status_deleted_secondupload: `Feedback was not implemented.`,
dashboard_campaign_applicants: `Applications`,
dashboard_campaign_finished: `Completed`,
dashboard_campaign_not_verified: `Campaign is in review`,
dashboard_campaign_offline: `Offline`,
dashboard_campaign_online: `Online`,
dashboard_campaign_participants: `Creator`,
dashboard_create_ugc_body: `Let authentic content creators create premium UGC videos for your business.`,
dashboard_create_ugc_button: `Create order`,
dashboard_create_ugc_title: `UGC video`,
dashboard_empty_body: (price: P,currency: P) => `Let's change that! Create your first UGC orders now from ${price as S}${currency as S}.` as const,
dashboard_empty_title: `Quite empty here`,
dashboard_empty_tour: `https://s3.eu-central-1.amazonaws.com/com.freebeeapp/public/webapp_videos/platform_tour.mp4`,
dashboard_title: `Your orders`,
deny_dialog_choose_feedback: `Leave your creator some feedback.`,
deny_dialog_disclaimer: `You can request changes from creators only once. Be sure to provide precise instructions about the adjustments you need.`,
deny_dialog_feedback: (number: P) => `Feedback ${number as S}` as const,
deny_dialog_max: `A maximum of five feedback comments is possible.`,
deny_dialog_send_feedback: `Send feedback`,
deny_dialog_title: `Request changes`,
denyReasonBadAge: `Too young or too old`,
denyReasonBadQuality: `Insufficient quality`,
denyReasonBadVoicing: `Voice does not fit`,
denyReasonIrrelevant: `Does not fit the brand`,
denyReasonOther: `Other`,
deny_reason_question: `Please tell us why and where you need some adjustments.`,
deselect: `Deselect`,
discount: `Discount code`,
drag_and_drop_body: `Add a maximum of six pictures or videos`,
drag_and_drop_caption: `File: MP4, JPG, PNG; min. 400x400`,
drag_and_drop_title: `Drag & Drop or choose File`,
error: `Error occured`,
error2: `Oops! An unexpected error has occurred.`,
error_reload_body: `Oops! An unexpected error has occurred. Please try reloading the page.`,
feedbackDialogDescription: `Help us improve your user experience! We value your feedback so that we can make our platform even better!`,
feedbackDialogSubmitButton: `Send`,
feedbackDialogSuccess: `Feedback successfully sent!`,
feedbackDialogTextfield: `feedback`,
feedbackDialogTitle: `Give feedback`,
full_slots_coupon_dialog_body: `For the next creator you book for your order you will receive`,
full_slots_coupon_dialog_body_empty: `If your order gets new applications today, you have the possibility to book these creators. For the next creator you book for your order you will receive`,
full_slots_coupon_dialog_expiration: `Your voucher code:`,
full_slots_coupon_dialog_title: `Here is your voucher code`,
full_slots_coupon_dialog_valid_until: `Date of expiry:`,
full_slots_normal_dialog_body: `You now have the opportunity to book more creators.

All open applications will be rejected automatically tonight.`,
full_slots_normal_dialog_title: `Book more creators`,
influencer_dialog_age: `Age:`,
influencer_dialog_birth_place: `City:`,
influencer_dialog_country: `Country:`,
influencer_dialog_deny_reason: `Select a reason`,
inspo_classic_desc_1: `Suitable for social media ads and organic content`,
inspo_classic_desc_2: `Focus on product and creator`,
inspo_classic_desc_3: `Ideal for physical products that are applied`,
inspo_classic_title_1: `Classic`,
inspo_classic_title_2: `UGC Video`,
inspo_digital_desc_1: `Suitable for apps and software solutions`,
inspo_digital_desc_2: `Focus on application`,
inspo_digital_desc_3: `ideal for food, insurance, travel`,
inspo_digital_title_1: `Digital`,
inspo_digital_title_2: `UGC Video`,
inspo_industry_desc_1: `Suitable for the construction and craft industries`,
inspo_industry_desc_2: `provide the Creator with cut-outs to create a ‘before and after’ effect`,
inspo_industry_desc_3: `Ideal for larger products`,
inspo_industry_title_1: `Industry`,
inspo_industry_title_2: `UGC Video`,
inspo_unboxing_desc_1: `Suitable for e-commerce & online marketplaces`,
inspo_unboxing_desc_2: `Focus on the ‘unboxing effect’`,
inspo_unboxing_desc_3: `Ideal for soundless videos with Creator or faceless videos`,
inspo_unboxing_title_1: `Unboxing & Gesichtslos`,
inspo_unboxing_title_2: `UGC Video`,
intro_campaign_overview_creators_send: (date: P) => `Please enter tracking number here until ${date as S}.` as const,
login_agb_button: `Terms & Conditions`,
login_agb_link: `https://ugc.stylink.com/en/terms-and-conditions/`,
login_button: `Login`,
login_code_label: `Code {{name}}`,
login_data_protection_button: `Privacy policy`,
login_email_label: `Email`,
login_error: `Oops! An unexpected error has occurred. `,
login_error_email_exists: `The stated email address already exists.`,
login_error_referral_code: `Referral Code invalid`,
login_error_too_many_attempts: `Too many attempts to register. Please try again later.`,
login_error_wrong_credentials: `Username and password do not match.`,
login_forgot_password_button: `Forgot password?`,
login_login_button: `Log in`,
login_login_text: `You don't have an account yet?`,
login_password_label: `Password`,
login_privacy_link: `https://ugc.stylink.com/en/privacy-policy/`,
login_register_button: `Create an account`,
login_register_confirmation_part1: `I agree with the`,
login_register_confirmation_part2: `terms & Conditions`,
login_register_confirmation_part3: `and the`,
login_register_confirmation_part4: `privacy policy.`,
login_register_confirmation_part5: `to.`,
login_register_firstname_label: `First name`,
login_register_referral_code: `Referral Code`,
login_register_submit_button: `Register`,
login_register_surname_label: `Last name`,
login_register_text: `You already have an account?`,
login_register_yup_code1: `Please add the code from your email.`,
login_register_yup_confirmation: `Terms & Conditions and privacy policy must be confirmed.`,
login_register_yup_firstname1: `First name must be longer than two characters.`,
login_register_yup_surname1: `Last name must be longer than two characters.`,
login_reset_password_body: `Don't worry! Please tell us your email address and we will send you a code to reset your password.`,
login_reset_password_title: `Reset password`,
login_slide_desc1: `Create an order according to your preferences and define the video script, video length, mentions and much more.`,
login_slide_desc2: `Creators can apply to your order and you decide who you want to work with.`,
login_slide_desc3: `In order to let them create your videos, ship your product to your selected creators.`,
login_slide_desc4: `You will receive your videos afterwards. If you wish for any adjustments, you will have the option of one change loop.`,
login_slide_title1: `Create your order`,
login_slide_title2: `Receive applications and select`,
login_slide_title3: `Ship your product`,
login_slide_title4: `Receive your videos`,
login_yup_email1: `Invalid email address.`,
login_yup_email2: `Email address must be stated.`,
login_yup_password1: `Password must be at least eight characters long.`,
login_yup_password2: `Password must contain at least one small letter.`,
login_yup_password3: `Password must contain at least one capital letter.`,
login_yup_password4: `Password must contain at least one number.`,
login_yup_password5: `Password must be stated.`,
more: `more`,
most_popular: `most popular`,
next: `Next`,
no: `No`,
no_auth_body: `It looks like you have no permission to this area.
                <br />
You could also try to reload the page.`,
no_auth_title: `Unfortunately, it doesn't go any further here`,
onboarding_other: `OTHERS`,
onboardingPlattformForAgencyButton: `FOR AGENCIES`,
onboardingPlattformForBrandsButton: `FOR BRANDS`,
onboarding_referral: `RECOMMENDATION`,
onboarding_title: `How did you hear about us?`,
payment_modal_cancel: `CANCEL`,
payment_modal_choose_method: `Choose your payment method:`,
payment_modal_choose_method_creditcard: `Credit card`,
payment_modal_choose_method_sepa: `SEPA`,
payment_modal_coupon_invalid: `This discount code is invalid.`,
payment_modal_method_choose_error: `Oops! An error has occurred while entering.`,
payment_modal_method_company_info_address: `Street, no.`,
payment_modal_method_company_info_city: `City`,
payment_modal_method_company_info_country: `Country`,
payment_modal_method_company_info_name: `Company`,
payment_modal_method_company_info_title: `Invoice address`,
payment_modal_method_company_info_vatnumber: `VAT`,
payment_modal_method_company_info_vatnumber_check: `My company has a valid VAT.`,
payment_modal_method_company_info_zip: `Postal code`,
payment_modal_pay: `PAY NOW`,
payment_modal_title: `Your order`,
payment_modal_yup_company_address: `Street and no. must be stated.`,
payment_modal_yup_company_city: `City must be stated.`,
payment_modal_yup_company_country: `Country must be stated.`,
payment_modal_yup_company_name: `Company must be stated.`,
payment_modal_yup_company_payment_method: `Payment method must be selected.`,
payment_modal_yup_company_vat_number1: `VAT must be stated.`,
payment_modal_yup_company_vat_number2: (country: P) => `Invalid VAT for ${country as S}.` as const,
payment_modal_yup_company_zip: `Postal code must be stated.`,
preferences_dialog_empty: `Oops! An unexpected error has occured.`,
preferences_dialog_no_answer: `No answer`,
preferences_dialog_title: `Product preferences`,
preview_invoice_body: `Your price is calculated as follows:`,
preview_invoice_brutto: `Gross`,
preview_invoice_count: `Amount of videos`,
preview_invoice_count_photos: `Number of images`,
preview_invoice_discount: `Discount`,
preview_invoice_netto: `Net`,
preview_invoice_reverse_charge: `Reverse charge`,
preview_invoice_title: `Pricing`,
preview_invoice_ust_19: `VAT 19%`,
preview_invoice_ust_20: `VAT 20%`,
pricing_ctas: (i: P) => `${i as S}x alternative Call-to-Action` as const,
pricing_hooks: (i: P) => `${i as S}x alternative hook` as const,
pricing_nano_service: `Storyboard`,
pricing_photo: `Image price`,
pricing_self_service: `Self service`,
pricing_video_duration: `Video duration`,
pricing_video_feature_medium: `Editing: Medium`,
pricing_video_feature_small: `Subtitles`,
pricing_video_type: `Video type`,
product_urls_label: `Product URLs (collapsible)`,
profile_settings_analytics_disabled: `Please reload the session to apply the change!`,
profile_settings_analytics_enable: `Activate analytics`,
profile_settings_analytics_unable: `Deactivate analytics`,
profile_settings_change_password: `Change password`,
profile_settings_email: `Email`,
profile_settings_firstname: `First name`,
profile_settings_password: `Password`,
profile_settings_save: `Save changes`,
profile_settings_save_error: `Oops! An error has occurred while saving.`,
profile_settings_surname: `Last name`,
profile_settings_title: `Manage profile`,
recommended: `recommended`,
save: `Save`,
saved: `Saved`,
scene: `Scene`,
select: `Select`,
set_apointment_link: `https://meetings-eu1.hubspot.com/tien-gruenewald/stylink-ugc-uk-ie`,
shipping_body: `Please select the shipping partner and enter the tracking number.`,
shipping_button: `Enter tracking number`,
shipping_other_service: `Shipping partner`,
shipping_sent: `Send`,
shipping_service: `Shipping partner`,
shipping_title: `Tracking number`,
shipping_tracking_code_label: `Tracking number`,
sidebar_headline_brand_settings: `Manage brand`,
sidebar_headline_create_campaign: `Create order`,
sidebar_headline_overview: `Order overview`,
sidebar_headline_profile_settings: `Manage profile`,
sidebar_item_brand_settings: `Manage brand`,
sidebar_item_campaigns: `Orders`,
sidebarItemContact: `support`,
sidebar_item_create_brand: `Create new brand`,
sidebar_item_feedback: `Feedback`,
sidebar_popup_coupon: `Top up your credit and secure <b>bonus</b>!`,
sidebar_popup_coupon_button: `Charge`,
sidebar_popup_expert: `Benefit from a <b>free of charge</b> consultation with our experts.`,
sidebar_popup_expert_button: `Set up appointment`,
sidebar_stats_active_campaign: `Active order`,
sidebar_stats_active_campaigns: `Active orders`,
test: (name: P,n: P) => `${name as S} Hello. ${n as S}` as const,
tutorial_do_not_show_again: `Don't show again`,
unboxing: `Unboxing`,
unboxing_creator: `Creator`,
unboxing_creator_description: `Scene 1: Product is unpacked, Creator is visible
Scene 2: Product is applie`,
unboxing_description: `Product videos for your online store, your Amazon shop or your website.`,
unboxing_faceless: `Faceless & product focus`,
unboxing_faceless_description: `Scene 1: Product packaging is held up to the camera, only hands are visible Scene 2: Product is applied`,
unboxing_five_videos: `Have 5 unboxing videos produced`,
unboxing_five_videos_desc: `Have 5 unboxing videos produced`,
unboxing_ten_videos: `10 videos`,
unboxing_ten_videos_desc: `Have 10 unboxing videos produced and receive a 7% discount`,
unboxing_twenty_videos: `20 videos`,
unboxing_twenty_videos_desc: `Have 20 unboxing videos produced and receive a 13% discount`,
unboxing_video: `Unboxing video`,
understood: `Okay!`,
upgrade_video_tutorial_body: `Upgrade your videos with music, subtitles or animations to boost your performance.`,
upgrade_video_tutorial_button: `Choose upgrade`,
upgrade_video_tutorial_joyride_content: `Click here for upgrades`,
upgrade_video_tutorial_title: `Upgrade your Videos.`,
user_role_accountant: `ACCOUNTANT`,
user_role_admin: `ADMIN`,
user_role_analyst: `ANALYST`,
user_role_marketer: `MARKETER`,
video_feature_medium_description: `✓ Animated captions
+ Logo
+ Call-to-Action`,
video_feature_small_description: `✓ Subtitles`,
wait: `Waiting`,
yes: `Yes`,
texterify_timestamp: `2025-02-10T13:26:52Z`
} as const