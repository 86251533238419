import { createContext, useCallback, useContext, useEffect, useState } from 'react'
import agent from '../agent'
import rootStore from '../stores/rootStore'
import { Country } from '../models/GeneralTypes'

export type Pricing = Awaited<ReturnType<typeof agent.Brands.getPricing>>
let promise: Promise<Pricing> | undefined
export const usePricing = () => {
  const [pricing, setPricing] = useState<Pricing | Error>()
  const getPricing = useCallback(async () => {
    setPricing(undefined)
    try {
      const country = rootStore.brandStore.selectedBrand?.brandCountry || Country.germany
      const result = await (promise ?? (promise = agent.Brands.getPricing({ country })))
      setPricing(result)
    } catch (e) {
      setPricing(e)
    }
  }, [])
  useEffect(() => {
    getPricing()
  }, [getPricing])
  return { pricing, getPricing }
}
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const PricingContext = createContext<Pricing>({} as any)
export const usePricingContext = () => useContext(PricingContext)
//   export const usePricingContextWithViews = (): Pricing | ReactElement<any> => {
//   const { pricing, getPricing } = usePricingContext()
//   if (pricing == null) return <CircularProgress />
//   if (pricing instanceof Error)
//     return (
//       <IconButton onClick={getPricing}>
//         <ErrorIcon />
//       </IconButton>
//     )
//   return pricing
// }
