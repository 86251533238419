import { Country } from '../models/GeneralTypes'

export const getCurrencySymbolByCode = (currencyCode: string): string => {
  const currencyMap: { [key: string]: string } = {
    eur: '€',
    gbp: '£',
    usd: '$',
    chf: 'CHF'
  }
  return currencyMap[currencyCode.toLowerCase()] || ''
}

export const getCurrencySymbolByCountry = (country: Country): string => {
  const currencyMap: { [key in Country]: string } = {
    de: '€',
    at: '€',
    ch: 'CHF',
    gb: '£'
  }
  return currencyMap[country] || ''
}
