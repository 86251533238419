/* Used for Videotyp and Videopackage */

import { Button, Grid, Radio, RadioGroup, Typography } from '@mui/material'
import React from 'react'
import { useFormContext } from 'react-hook-form'
import ReactPlayer from 'react-player'
import { useStrings } from '../../../assets/localization/strings'
import { numberToEuro } from '../../../reusableUtils/Helpers'
import { useReadonly } from '../../../reusableUtils/useReadonly'
import { color, theme } from '../../../theme'
import InfoChip from './InfoChip'
import rootStore from '../../../stores/rootStore'
import { getCurrencySymbolByCountry } from '../../../reusableUtils/currency'

export interface IRadioCardGroupOption<T extends string = string> {
  content?: string
  showControls: boolean
  title: string
  value: T
  description: string
  price?: number
  isPriceAdditional: boolean // '+' in front of price or not
  discount?: number // 0-1 = 0%-100%
  topBadge?: string
  addButton?: boolean
  thumbnail?: string
  disabled?: boolean
}

interface IReactHookFormRadioCardGroupFullImageProps {
  name: string
  options: IRadioCardGroupOption[]
  videosOnBottom?: boolean
  required?: boolean
  multiple?: boolean
  defaultValue?: string
  md?: number
}

const ReactHookFormRadioCardGroupFullImage = ({ name, options, videosOnBottom = false, multiple, required = false, md = 4 }: IReactHookFormRadioCardGroupFullImageProps) => {
  const { watch, setValue } = useFormContext()
  const current = watch(name)
  const isReadonly = useReadonly(name)
  const strings = useStrings()

  const renderPrice = (o: IRadioCardGroupOption, selected: boolean, inTitle = false) => {
    if (!o.price) return <></>
    const actualPrice = Math.round(o.price * (o.discount ?? 1))

      const formatPrice = (price: number, currency: string) => {
        return currency === '£' ? `${currency}${numberToEuro(price)}` : `${numberToEuro(price)}${currency}`
      }


    const position = !inTitle ? { position: 'absolute', bottom: 9, right: 9 } : {}
    const currencySymbol = rootStore.brandStore.selectedBrand?.brandCountry ? getCurrencySymbolByCountry(rootStore.brandStore.selectedBrand?.brandCountry) : '€'

  return (
    <Grid item xs={12} textAlign='right' sx={position}>
      {o.discount && o.discount < 1 && (
        <Typography
          sx={{
            fontSize: '14px',
            fontWeight: 400,
            color: selected ? color.stylinkDarkPurple : 'inherit',
            textDecoration: 'line-through'
          }}
          display='inline'
          pr={1}>
          {o.isPriceAdditional && '+'}
          {formatPrice(o.price, currencySymbol)}
        </Typography>
      )}
      <Typography
        sx={{
          fontSize: inTitle ? '16px' : '20px',
          fontWeight: 600,
          color: selected ? color.stylinkDarkPurple : 'inherit'
        }}
        display='inline'>
        {o.isPriceAdditional && '+'}
        {formatPrice(actualPrice, currencySymbol)}
      </Typography>
    </Grid>
  )
  }

  const renderTitle = (o: IRadioCardGroupOption) => {
    if (o.description) return <Typography sx={{ fontSize: '17px', fontWeight: 600, color: color.grey2, textAlign: 'left' }}>{o.title}</Typography>

    return (
      <Grid container>
        <Grid item container xs={8} alignItems='center'>
          <Typography sx={{ fontSize: '16px', fontWeight: 600, color: color.grey2, textAlign: 'left' }}>{o.title}</Typography>
        </Grid>
        <Grid item container xs={4} alignItems='center'>
          {renderPrice(o, true, true)}
        </Grid>
      </Grid>
    )
  }

  const onAddButton = (o: IRadioCardGroupOption, selected: boolean) => {
    const isArray = Array.isArray(current)
    if (isArray && multiple) {
      const removed = current.filter(it => it != o.value)
      setValue(name, selected ? removed : removed.concat(o.value))
    } else if (isArray) {
      if (!required && selected) {
        setValue(name, [])
      } else {
        setValue(name, [o.value])
      }
    } else {
      if (!required && selected) {
        setValue(name, undefined)
      } else {
        setValue(name, o.value)
      }
    }
  }

  const renderButton = (o: IRadioCardGroupOption, selected: boolean) => (
    <Button variant={selected ? 'contained' : 'outlined'} type='button' fullWidth sx={{ marginTop: '8px' }} onClick={() => onAddButton(o, selected)} disabled={isReadonly}>
      {selected ? strings.deselect : strings.select}
    </Button>
  )

  const renderVideo = (o: IRadioCardGroupOption) => (
    <Grid item xs={12}>
      <div className='videoPlayerWrapper'>
        <ReactPlayer url={o.content} width='100%' height='auto' controls={o.showControls} />
      </div>
    </Grid>
  )

  return (
    <RadioGroup>
      <Grid container spacing={2}>
        {options.map(o => {
          const selected = Array.isArray(current) ? current.includes(o.value) : current == o.value
          return (
            <Grid item key={o.value} xs={12} sm={6} md={md} sx={{ [theme.breakpoints.only('xs')]: { mt: 2 } }}>
              <Button
                sx={{
                  border: '1.5px solid',
                  borderColor: selected ? color.stylinkDarkPurple : color.grey3,
                  borderRadius: '10px',
                  textTransform: 'none',
                  alignItems: 'flex-start',
                  position: 'relative',
                  padding: 0,
                  background: selected ? color.backgroundPurple : 'inherit',
                  height: o.addButton ? '90%' : '100%',
                  width: '100%'
                }}
                value={o.value}
                disabled={isReadonly}
                onClick={() => onAddButton(o, selected)}>
                <Radio checked={selected} sx={{ position: 'absolute', right: '0px', top: '0px' }} value={o.value} />
                {o.topBadge && <InfoChip label={o.topBadge} />}
                <Grid container>
                  {!videosOnBottom && renderVideo(o)}
                  <Grid item container xs={12} sx={{ padding: '20px 20px 20px 20px', paddingBottom: o.description ? '50px' : '20px' }}>
                    <Grid item container xs={12}>
                      {renderTitle(o)}
                    </Grid>
                    <Grid item xs={12}>
                      <Typography sx={{ fontSize: '14px', fontWeight: 400, color: color.grey2, textAlign: 'left', whiteSpace: 'pre-line' }}>{o.description}</Typography>
                    </Grid>
                    {!!o.description && renderPrice(o, selected)}
                  </Grid>
                </Grid>
              </Button>
              {o.addButton && renderButton(o, selected)}
            </Grid>
          )
        })}
      </Grid>
    </RadioGroup>
  )
}

export default ReactHookFormRadioCardGroupFullImage
