import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import { Alert, Fab, Grid, Hidden, IconButton, TextField, Tooltip, Typography } from '@mui/material'
import React, { Fragment, useEffect } from 'react'
import { useFormContext, useWatch } from 'react-hook-form'
import { useStrings } from '../../assets/localization/strings'
import { color, theme } from '../../theme'
import ReactHookFormSelect, { IOption } from '../common/form/ReactHookFormSelect'
import { IUGCCampaign } from './UGCCampaignForm'
import { VideoType } from '../../models/GeneralTypes'
import ReactHookFormRadioCardGroup from '../common/form/ReactHookFormRadioCardGroup'
import { useCustomFieldArray } from '../../reusableUtils/useCustomFieldArray'
import { useReadonly } from '../../reusableUtils/useReadonly'
import AddIcon from '@mui/icons-material/Add'
import DeleteIcon from '@mui/icons-material/Delete'
import { usePricingContext } from '../../reusableUtils/pricing'

const CampaignBudget = () => {
  const { control, formState, setValue, getValues, trigger } = useFormContext<IUGCCampaign>()
  const number = useWatch({ control, name: 'numberOfVideos' })
  const { fields, append, remove, update } = useCustomFieldArray({ control, name: 'productUrls', keyName: 'id' })
  const strings = useStrings()
  const pricing = usePricingContext()

  const options: IOption[] = [...Array(20)].map((_, i) => i + 1).map(i => ({ label: `${i}`, value: i }))
  options[2] = { label: '3', value: 3, note: strings.recommended }
  // options[4] = { label: '5', value: 5, note: strings['5_discount'] }
  // options[9] = { label: '10', value: 10, note: strings['10_discount'] }

  const isReadonly = useReadonly('scenes')
  const isUnboxing = getValues('videoType') === VideoType.unboxing
  const numberOfVideos = useWatch({ control, name: 'numberOfVideos' })

  const videosCountOptions = [
    {
      label: strings.unboxing_five_videos,
      value: 5,
      description: strings.unboxing_five_videos_desc,
      price: pricing[VideoType.unboxing] * 5
    },
    { label: strings.unboxing_ten_videos, value: 10, description: strings.unboxing_ten_videos_desc, price: pricing[`${VideoType.unboxing}_10`] * 10 },
    { label: strings.unboxing_twenty_videos, value: 20, description: strings.unboxing_twenty_videos_desc, price: pricing[`${VideoType.unboxing}_20`] * 20 }
  ]

  useEffect(() => {
    if (isUnboxing && numberOfVideos < 5) {
      setValue('numberOfVideos', 5)
    }
  }, [isUnboxing, numberOfVideos])

  if (getValues('videoType') === VideoType.unboxing) {
    return (
      <Grid container>
        <Grid item xs={12}>
          <Typography variant='h4'>{strings.campaign_creation_budget_video_count}</Typography>
        </Grid>
        <Grid item xs={12} mt={2}>
          <ReactHookFormRadioCardGroup additionalPrice={false} name='numberOfVideos' defaultValue={5} md={4} options={videosCountOptions} />
        </Grid>
        <Fragment>
          <Grid item xs={12} mt={isUnboxing ? 4 : 0}>
            <Typography variant='h5'>{strings.product_urls_label}</Typography>
          </Grid>
          <Grid item xs={12} mt={2}>
            {fields.map((field, i) => (
              <Grid container mt={2} alignItems='center' key={i}>
                <Grid item xs={11}>
                  <TextField
                    value={field.value}
                    onChange={e => {
                      update(i, { ...field, value: e.target.value })
                      // trigger only when necessary to avoid lags
                      if (e.target.value.length < 120 != (field.value || '').length < 120) trigger(`productUrls.${i}`)
                    }}
                    label={'Produkt-URL' + (i + 1)}
                    error={!!formState.errors.productUrls?.[i]}
                    helperText={formState.errors.productUrls?.[i]?.value?.message}
                    type='outlined'
                    fullWidth
                    size='small'
                    disabled={isReadonly}
                  />
                </Grid>
                {!isReadonly && fields.length > 1 && (
                  <Grid item xs={1} textAlign='center'>
                    <DeleteIcon fontSize='small' onClick={() => remove(i)} />
                  </Grid>
                )}
              </Grid>
            ))}
            {!isReadonly && fields.length < 5 && (
              <Grid item xs={12} textAlign='center' mt={2}>
                <Fab color='primary' aria-label='add' size='small' onClick={() => append({ id: `${fields.length}`, value: '' })}>
                  <AddIcon />
                </Fab>
              </Grid>
            )}
          </Grid>
        </Fragment>
      </Grid>
    )
  }

  return (
    <Grid container>
      <Grid item xs={12}>
        <Typography variant='h4'>{strings.campaign_creation_budget_video_count}</Typography>
      </Grid>
      {number < 3 && (
        <Grid item xs={12} mt={2}>
          <Alert severity='warning'>{strings.campaign_creation_budget_video_count_tip}</Alert>
        </Grid>
      )}
      <Grid item xs={12} mt={2}>
        <Typography variant='body2'>
          {strings.campaign_creation_budget_question}

          <Hidden smDown>
            <Tooltip title={strings.campaign_creation_budget_tip}>
              <IconButton>
                <InfoOutlinedIcon fontSize='small' />
              </IconButton>
            </Tooltip>
          </Hidden>
        </Typography>
      </Grid>
      <Grid container item xs={12} mt={2}>
        <Grid item xs={12} sm={6} pr={2}>
          <ReactHookFormSelect name='numberOfVideos' label={strings.campaign_creation_budget_videos_label} options={options} />
        </Grid>
        <Grid item xs={12} sm={6} pr={2} sx={{ [theme.breakpoints.only('xs')]: { mt: 2 } }} container alignItems='center'>
          <Typography variant='body2' display='inline'>
            {strings.campaign_creation_budget_info1}{' '}
            <Typography paragraph={false} display='inline' color={color.stylinkPurple} sx={{ textDecorationColor: color.stylinkPurple }}>
              {number == 1 ? strings.campaign_creation_budget_creator(number) : strings.campaign_creation_budget_creators(number)}
            </Typography>{' '}
            {strings.campaign_creation_budget_info2}
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default CampaignBudget
