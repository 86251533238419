import { FormControl, FormHelperText, InputLabel, MenuItem, Select, Stack, Typography } from '@mui/material'
import React from 'react'
import { Controller } from 'react-hook-form'
import { useReadonly } from '../../../reusableUtils/useReadonly'
import { color } from '../../../theme'

export interface IOption<V extends string | number = string | number> {
  value: V
  label: string
  note?: string
}

interface IReactHookFormSelectProps {
  label: string
  name: string
  options: IOption[]
  showHelperError?: boolean
  disabled?:boolean
}

const ReactHookFormSelect = ({ label, name, options, showHelperError = true, disabled }: IReactHookFormSelectProps) => {
  const isReadonly = useReadonly(name)
  return (
    <Controller
      name={name}
      render={({ field, fieldState: { error } }) => (
        <FormControl fullWidth size='small'>
          <InputLabel>{label}</InputLabel>
          <Select {...field} label={label} variant='outlined' error={!!error} disabled={isReadonly || disabled}>
            {options.map(o => (
              <MenuItem key={o.value} value={o.value}>
                <Stack direction='row' alignItems='center'>
                  {o.label}
                  {o.note && (
                    <Typography component='div' variant='body1' fontSize='12px' p='2px 4px' ml={1} color='white' borderRadius='6px' sx={{ backgroundColor: color.stylinkPurple }}>
                      {o.note}
                    </Typography>
                  )}
                </Stack>
              </MenuItem>
            ))}
          </Select>
          {showHelperError && error?.message && <FormHelperText error>{error.message}</FormHelperText>}
        </FormControl>
      )}
    />
  )
}

export default ReactHookFormSelect
