import { Alert, Button, Grid, Stack } from '@mui/material'
import React, { useState } from 'react'
import NoParticipants from '../../../assets/illustrations/im_no-participants.png'
import { useStrings } from '../../../assets/localization/strings'
import { CampaignInsightsUgc, UgcContent } from '../../../requestModels'
import { State } from '../../../reusableUtils/Helpers'
import '../../../reusableUtils/throttlePlugin'
import styles from '../../../style/contentCampaign/content.module.css'
import { MockView } from '../../common/MockView'
import InfluencerCard from '../Influencer'
import { DenyDialog } from './DenyDialog'

type Props = State<'contentForCheck', UgcContent[]> & {
  campaignInsights: CampaignInsightsUgc
  urlParams: {
    campaignId: string
    brandId: string
    step: 'applications' | 'creators' | 'check' | 'content'
  }
  acceptContent: (influencerId: string) => Promise<void>
  filterContentForCheck: (influencerId: string) => void
}

export const CheckContent = ({ campaignInsights, contentForCheck, setContentForCheck, acceptContent, filterContentForCheck }: Props) => {
  const { campaignId, brandId } = campaignInsights
  const [infl, setInfl] = useState<UgcContent>()
  const strings = useStrings()

  const renderNoParticipants = () => {
    const atLeastOneAccepted = campaignInsights.accepted > 0
    return (
      <Grid container direction='column' justifyContent='center' alignItems='center' className={styles.noParticipantsContainer}>
        <Grid item>
          <img className={styles.noParticipants} src={NoParticipants} alt='no participants' />
        </Grid>
        <Grid item>
          <h2>{atLeastOneAccepted ? strings.campaign_overview_check_empty_done_title : strings.campaign_overview_check_empty_title}</h2>
        </Grid>
        <Grid item>
          <span>{atLeastOneAccepted ? strings.campaign_overview_check_empty_done_body : strings.campaign_overview_check_empty_body}</span>
        </Grid>
      </Grid>
    )
  }

  const handleOpenDenyDialog = (inflDialog: UgcContent) => {
    setInfl(inflDialog)
  }
  const handleCloseDenyDialog = (influencerId?: string) => {
    if (influencerId) filterContentForCheck(influencerId)
    setInfl(undefined)
  }

  if (!contentForCheck.length) return renderNoParticipants()

  return (
    <Grid container style={{ margin: '32px 0 15px 0' }}>
      {infl && campaignId && <DenyDialog handleClose={handleCloseDenyDialog} open={true} influencer={infl} campaignId={campaignId} brandId={brandId} />}
      {campaignInsights.videoFeature && (
        <Grid item xs={12}>
          <Alert severity='info'>{strings.campaign_overview_check_info}</Alert>
        </Grid>
      )}
      <Grid container spacing={2} className={styles.cardContainer} mt={1}>
        {contentForCheck &&
          contentForCheck.map(influencer => (
            <Grid item key={influencer.influencerId}>
              <InfluencerCard
                key={`${influencer.influencerId}`}
                influencer={influencer}
                content={influencer.contentLinks.map(linkObj => linkObj.link)}
                acceptInfluencer={inf => acceptContent(inf)}
                denyInfluencer={async () => {}}
                campaign={campaignInsights}
                showDenySteps={false}
                openDialog={handleOpenDenyDialog}
                horizontalButtons
              />
              <MockView name={`CheckContent ${influencer.influencerId}`}>
                <Stack direction='row'>
                  <Button
                    onClick={() => {
                      influencer.contentLinks[0] =
                        'https://s3.eu-central-1.amazonaws.com/freebee-app-user/user/campaign/468b46b3-c1a1-4f38-b0d7-0fe285723c49/3768afc4-93d2-49fd-8465-f77e666c010f/0/F1BDFD7B-D23C-45C3-AAF6-F43B943636CA-6170-0000143A0C325697.mov'
                      setContentForCheck(p => [...p])
                    }}>
                    Set Vertical Video
                  </Button>
                  <Button
                    onClick={() => {
                      influencer.contentLinks[0] = 'https://s3.eu-central-1.amazonaws.com/com.freebeeapp/dog.mp4'
                      setContentForCheck(p => [...p])
                    }}>
                    Set Horizontal Video
                  </Button>
                </Stack>
              </MockView>
            </Grid>
          ))}
      </Grid>
    </Grid>
  )
}
export default CheckContent
