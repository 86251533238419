import { Grid, Typography } from '@mui/material'
import React from 'react'
import { useFormContext, useWatch } from 'react-hook-form'
import { useStrings } from '../../assets/localization/strings'
import { HubspotIndustry } from '../../models/GeneralTypes'
import ReactHookFormAudioDropzone from '../common/form/ReactHookFormAudioDropzone'
import ReactHookFormCropperDropzone from '../common/form/ReactHookFormCropperDropzone'
import ReactHookFormSelect, { IOption } from '../common/form/ReactHookFormSelect'
import ReactHookFormTextField from '../common/form/ReactHookFormTextField'
import { IBrandForm } from './BrandForm'

const BrandDetails = ({ mt }: { mt: number }) => {
  const strings = useStrings()
  const { control } = useFormContext<IBrandForm>()
  const isAgency = useWatch({ control, name: 'isAgency' })
  const brandLogo = useWatch({ control, name: 'brandLogo' })
  const label = isAgency ? strings.brand_creation_info_agency_logo : strings.brand_creation_info_brand_logo

  return (
    <>
      <Grid item xs={12} mt={mt}>
        <Typography variant='h5'>{label}</Typography>
      </Grid>
      <Grid container xs={12} spacing={2} mt={2}>
        <Grid item xs={12} sm={4}>
          <ReactHookFormCropperDropzone name='brandLogo' label={label} accept='image/jpeg, image/png' height={'185px'} width={brandLogo ? '185px' : '90%'} aspectRatio={1 / 1} />
        </Grid>
        <Grid item xs={12} sm={2} />
        <Grid item xs={12} sm={6}>
          <ReactHookFormAudioDropzone name='brandAudioUrl' label={strings.brand_creation_info_brand_audio} accept='audio/*' height='185px' width='90%' />
        </Grid>
      </Grid>
    </>
  )
}

const BrandInfo = ({ campaignForm }: { campaignForm?: boolean }) => {
  const strings = useStrings()
  const { control } = useFormContext<IBrandForm>()
  const isAgency = useWatch({ control, name: 'isAgency' })

 const industryOptions: IOption<string>[] = [
   { value: HubspotIndustry.Fashion_Accessoires, label: strings.brand_creation_info_industry_option_apparel },
   { value: HubspotIndustry.Beauty_Make_Up, label: strings.brand_creation_info_industry_option_beauty },
   { value: HubspotIndustry.Interior_DIY, label: strings.brand_creation_info_industry_option_interior_diy },
   { value: HubspotIndustry.Mommy_Kids, label: strings.brand_creation_info_industry_option_mommy_kids },
   { value: HubspotIndustry.Gesundheit_Fitness, label: strings.brand_creation_info_industry_option_health },
   { value: HubspotIndustry.Technik, label: strings.brand_creation_info_industry_option_technik },
   { value: HubspotIndustry.Buecher_Schreibwaren, label: strings.brand_creation_info_industry_option_books },
   { value: HubspotIndustry.Travel, label: strings.brand_creation_info_industry_option_travel },
   { value: HubspotIndustry.Food, label: strings.brand_creation_info_industry_option_food },
   { value: HubspotIndustry.Luxury, label: strings.brand_creation_info_industry_option_luxury },
   { value: HubspotIndustry.Haustier_Haustierpflege, label: strings.brand_creation_info_industry_option_pets },
   { value: HubspotIndustry.Digital_Products_Apps, label: strings.brand_creation_info_industry_option_digital_products },
   { value: HubspotIndustry.Finance_Insurance, label: strings.brand_creation_info_industry_option_finance },
   { value: HubspotIndustry.Diverse, label: strings.brand_creation_info_industry_option_diverse }
 ].filterNotNull()

  const countryOptions = [
    { value: 'de', label: strings.brand_creation_country_germany as string },
    { value: 'at', label: strings.brand_creation_country_austria as string },
    { value: 'ch', label: strings.brand_creation_country_switzerland as string },
    { value: 'gb', label: strings.brand_creation_country_england as string }
  ]

  return (
    <Grid container>
      <Grid item xs={12}>
        <Typography variant='h5'>{isAgency ? strings.brand_creation_info_agency_name_question : strings.brand_creation_info_brand_name_question}</Typography>
      </Grid>
      <Grid item xs={12} mt={2}>
        <ReactHookFormTextField name='brandName' label={isAgency ? strings.brand_creation_info_agency_name : strings.brand_creation_info_brand_name} />
      </Grid>
      {!campaignForm && (
        <>
          <Grid item xs={12} mt={4}>
            <Typography variant='h5'>{strings.brand_creation_info_country_question}</Typography>
          </Grid>
          <Grid item xs={12} mt={2}>
            <ReactHookFormSelect name='brandCountry' label={strings.brand_creation_country_label + '*'} options={countryOptions} />
          </Grid>
        </>
      )}
      <BrandDetails mt={4} />
      {!campaignForm && (
        <>
          <Grid item xs={12} mt={2}>
            <Typography variant='h5'>{strings.brand_creation_info_industry}</Typography>
          </Grid>
          <Grid item xs={12} mt={2}>
            <ReactHookFormSelect name='industry' label={strings.brand_creation_info_industry + '*'} options={industryOptions} />
          </Grid>
        </>
      )}
    </Grid>
  )
}
export const BrandInfoJustLogo = () => (
  <Grid container>
    <BrandDetails mt={0} />
  </Grid>
)

export default BrandInfo
