import ErrorIcon from '@mui/icons-material/Error'
import { Box, Button, CircularProgress, Dialog, IconButton, Link, Menu, MenuItem, Stack, Typography } from '@mui/material'
import { observer } from 'mobx-react'
import { useSnackbar } from 'notistack'
import React, { useContext, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import DownloadContentIcon from '../../../assets/icons/ic_download_content.svg'
import ThreeStarsIcon from '../../../assets/icons/ic_upgrade_package.svg'
import { useStrings } from '../../../assets/localization/strings'
import { VideoFeature } from '../../../models/GeneralTypes'
import { UgcContentFinished } from '../../../requestModels'
import { usePricing } from '../../../reusableUtils/pricing'
import rootStore from '../../../stores/rootStore'
import PaymentModal from '../../common/PaymentModal'
import { Player, PlayerBottomText } from '../../common/Player'
import ReactHookFormRadioCardGroupFullImage from '../../common/form/ReactHookFormRadioCardGroupFullImage'
import { getVideoFeatureOptions } from '../../createUgcCampaign/CampaignAdditionalFeatures'
import { ContentCampaignOverviewContext } from '../ContentCampaignOverview'
import { ChevronLeft, ChevronRight } from '@mui/icons-material'
import { ImageViewer } from '../../common/ImageViewer'

type IVideoFeatureForm = { videoFeature: VideoFeature | undefined }

export const getEditedLinks = (influencer: UgcContentFinished) => {
  const { rawContent, editedContent, videoFeature, needsContentToBeEdited } = influencer

  return {
    rawContent,
    editedContent,
    contentToDisplay: editedContent || rawContent,
    canUpgrade: !videoFeature || videoFeature == VideoFeature.small, // in this case he can upgrade
    beingEdited: needsContentToBeEdited
  }
}

export const upgradeVideoButtonClass = 'upgrade-button'
type Props = { pricingObj: ReturnType<typeof usePricing>; influencer: UgcContentFinished }
const ContentView = observer(({ pricingObj: { pricing, getPricing }, influencer }: Props) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const [showUpgrade, setShowUpgrade] = useState(false)
  const [showPayment, setShowPayment] = useState(false)
  const { campaign, reload } = useContext(ContentCampaignOverviewContext)
  const { rawContent, editedContent, contentToDisplay, canUpgrade, beingEdited } = getEditedLinks(influencer)
  const [currentMediaIndex, setCurrentMediaIndex] = useState(0)

  const strings = useStrings()
  const { enqueueSnackbar } = useSnackbar()

  const methods = useForm<IVideoFeatureForm>({
    defaultValues: {
      videoFeature: undefined
    }
  })
  const { watch } = methods

  const upgrade = async (coupon: string) => {
    const videoFeature = watch('videoFeature')
    if (!videoFeature) {
      alert('No Video Feature selected')
      return
    }
    await rootStore.campaignStore.upgradeVideo({
      brandId: campaign.brandId,
      campaignId: campaign.campaignId,
      influencerId: influencer.influencerId,
      videoFeature,
      coupon
    })
    setShowPayment(false)
    reload()
    enqueueSnackbar(strings.campaign_overview_content_upgraded_message, { variant: 'success' })
  }

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation()
    setAnchorEl(event.currentTarget)
  }

  const handleClose = (event: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
    event.stopPropagation()
    setAnchorEl(null)
  }

  const renderVideoFeatures = () => {
    if (pricing == null) return <CircularProgress />
    if (pricing instanceof Error)
      return (
        <IconButton onClick={getPricing}>
          <ErrorIcon />
        </IconButton>
      )
    return (
      <ReactHookFormRadioCardGroupFullImage
        name='videoFeature'
        options={getVideoFeatureOptions(strings, pricing, influencer.videoFeature ? [influencer.videoFeature] : [])}
        required={true}
      />
    )
  }

  const renderUpgradeChip = () =>
    (!influencer.videoFeature || influencer.videoFeature == VideoFeature.small) && (
      <Box
        component='button'
        sx={{ p: '0px 6px', backgroundColor: theme => theme.palette.primary.main, borderRadius: '20px' }}
        onClick={() => {
          setShowUpgrade(true)
        }}>
        <Stack direction='row' alignItems='center' className={upgradeVideoButtonClass}>
          <img src={ThreeStarsIcon} style={{ width: '12px', marginRight: '6px' }} />
          <Typography variant='caption' fontWeight='600' color='white'>
            {strings.campaign_overview_content_upgrade_label}
          </Typography>
        </Stack>
      </Box>
    )
  const renderBeingEditedChip = () =>
    beingEdited && (
      <Box sx={{ ml: '7px', mt: '7px', mr: 'auto', p: '6px', backgroundColor: theme => theme.palette.primary.main, borderRadius: 1 }}>
        <Typography variant='caption' fontWeight='800' color='white'>
          {strings.campaign_overview_content_being_edited}
        </Typography>
      </Box>
    )
  const renderDownloadChip = () => {
    const hasEditedContent = Array.isArray(editedContent) && editedContent.length > 0
    const hasRawContent = Array.isArray(rawContent) && rawContent.length > 0

    // Determine the current video links based on the index
    const currentEditedContent = hasEditedContent ? editedContent[currentMediaIndex] : null
    const currentRawContent = hasRawContent ? rawContent[currentMediaIndex] : null

    return (
      <Box>
        {(currentEditedContent || currentRawContent) && (
          <Menu anchorEl={anchorEl} open={!!anchorEl} onClose={handleClose}>
            {currentEditedContent && (
              <MenuItem>
                <Link href={currentEditedContent.link} target='_blank' underline='none' color='black'>
                  {strings.campaign_overview_content_download}
                </Link>
              </MenuItem>
            )}
            {currentRawContent && (
              <MenuItem>
                <Link href={currentRawContent.link} target='_blank' underline='none' color='black'>
                  {strings.campaign_overview_content_raw_download}
                </Link>
              </MenuItem>
            )}
          </Menu>
        )}
        <Box component='a' {...(currentEditedContent || currentRawContent ? { onClick: handleClick } : { href: currentRawContent?.link || '#', target: '_blank' })}>
          <Box component='img' src={DownloadContentIcon} alt={strings.campaign_overview_content_download} width='15px' height='20px' color={theme => theme.palette.primary.main} />
        </Box>
      </Box>
    )
  }

  const renderUpgradeDialog = () => (
    <Dialog open={showUpgrade} onClose={() => setShowUpgrade(false)} fullWidth maxWidth='md'>
      <Box p={6}>
        {renderVideoFeatures()}
        {watch('videoFeature') && (
          <Button
            fullWidth
            variant='contained'
            sx={{ ml: 'auto', mt: '24px' }}
            onClick={() => {
              setShowUpgrade(false)
              setShowPayment(true)
            }}>
            {strings.next}
          </Button>
        )}
      </Box>
    </Dialog>
  )

  const handlePrevContent = () => {
    if (Array.isArray(contentToDisplay) && contentToDisplay.length > 0) {
      setCurrentMediaIndex(prev => (prev === 0 ? contentToDisplay.length - 1 : prev - 1))
    }
  }

  const handleNextContent = () => {
    if (Array.isArray(contentToDisplay) && contentToDisplay.length > 0) {
      setCurrentMediaIndex(prev => (prev + 1) % contentToDisplay.length)
    }
  }
  const isVideo = contentToDisplay[currentMediaIndex].link.includes('video')

  return (
    <FormProvider {...methods}>
      <Box height='100%' p={1}>
        <PaymentModal open={showPayment} handleClose={() => setShowPayment(false)} callback={upgrade} videoFeature={watch('videoFeature')} numberOfVideos={1} />
        {renderUpgradeDialog()}
        <Box sx={{ position: 'relative' }}>
          {isVideo ? (
            <Player src={contentToDisplay[currentMediaIndex].link} height={340} width={192}>
              <>
                <Stack direction='row' alignItems='center' justifyContent='space-between' sx={{ mt: 1, mr: 2, ml: 1 }}>
                  {canUpgrade ? renderUpgradeChip() : renderBeingEditedChip()}
                  {renderDownloadChip()}
                </Stack>
                <PlayerBottomText icon={influencer?.image} name={influencer.first_name} />
              </>
            </Player>
          ) : (
            <ImageViewer src={contentToDisplay[currentMediaIndex].link} height={340} width={192}>
              <>
                <Stack direction='row' alignItems='center' justifyContent='space-between' sx={{ mt: 1, mr: 2, ml: 1 }}>
                  {canUpgrade ? renderUpgradeChip() : renderBeingEditedChip()}
                  {renderDownloadChip()}
                </Stack>
                <PlayerBottomText icon={influencer?.image} name={influencer.first_name} />
              </>
            </ImageViewer>
          )}
          {/* Navigation Arrows */}
          <IconButton
            onClick={handlePrevContent}
            sx={{
              position: 'absolute',
              left: 8,
              top: '50%',
              transform: 'translateY(-50%)',
              bgcolor: 'rgba(0, 0, 0, 0.5)',
              '&:hover': { bgcolor: 'rgba(0, 0, 0, 0.7)' },
              color: 'white'
            }}>
            <ChevronLeft />
          </IconButton>

          <IconButton
            onClick={handleNextContent}
            sx={{
              position: 'absolute',
              right: 8,
              top: '50%',
              transform: 'translateY(-50%)',
              bgcolor: 'rgba(0, 0, 0, 0.5)',
              '&:hover': { bgcolor: 'rgba(0, 0, 0, 0.7)' },
              color: 'white'
            }}>
            <ChevronRight />
          </IconButton>

          {/* Video Counter */}
          <Box
            sx={{
              position: 'absolute',
              right: 16,
              bottom: 0,
              bgcolor: 'rgba(0, 0, 0, 0.5)',
              color: 'white',
              px: 1,
              py: 0.5,
              borderRadius: 1,
              fontSize: '0.875rem'
            }}>
            {currentMediaIndex + 1} / {Array.isArray(contentToDisplay) && contentToDisplay.length}
          </Box>
        </Box>
      </Box>
    </FormProvider>
  )
})

export default ContentView
