import AddIcon from '@mui/icons-material/Add'
import DeleteIcon from '@mui/icons-material/Delete'
import { Button, Fab, FormControlLabel, Grid, InputAdornment, Radio, RadioGroup, TextField, Typography } from '@mui/material'
import React, { Fragment, useEffect, useState } from 'react'
import { useFormContext, useWatch } from 'react-hook-form'
import { useParams } from 'react-router-dom'
import { useStrings } from '../../assets/localization/strings'
import { Service, VideoType } from '../../models/GeneralTypes'
import { numberToEuro } from '../../reusableUtils/Helpers'
import { usePricingContext } from '../../reusableUtils/pricing'
import { useCustomFieldArray } from '../../reusableUtils/useCustomFieldArray'
import { useReadonly } from '../../reusableUtils/useReadonly'
import ReactHookFormDropzone from '../common/form/ReactHookFormDropzone'
import ReactHookFormRadioCardGroup, { IRadioCardGroupOption } from '../common/form/ReactHookFormRadioCardGroup'
import type { IUGCCampaign } from './UGCCampaignForm'
import { getCurrencySymbolByCountry } from '../../reusableUtils/currency'
import rootStore from '../../stores/rootStore'

const Scenes = () => {
  const {
    control,
    formState: { errors }
  } = useFormContext<IUGCCampaign>()
  const { fields, update, append } = useCustomFieldArray({ control, name: 'scenes', keyName: 'id' })
  const isReadonly = useReadonly('scenes')
  const strings = useStrings()
  const placeholders = [strings.campaign_creation_content_scenes_placeholder1]

  const add = () => append({ id: `${fields.length}`, value: '' })

  useEffect(() => {
    if (!fields.length) add()
  }, [JSON.stringify(fields)])

  return (
    <Grid item xs={12} mt={2}>
      {fields.map((field, i) => (
        <Grid container mt={2} alignItems='center' key={i}>
          <Grid item xs={11}>
            <TextField
              rows={6}
              multiline
              value={field.value}
              name={`scene-${i}`}
              onChange={e => update(i, { ...field, value: e.target.value })}
              label={strings.campaign_creation_content_scene_placeh}
              type='outlined'
              fullWidth
              size='small'
              placeholder={placeholders[i] ?? strings.campaign_creation_content_scene_placeh}
              InputLabelProps={{ shrink: true }}
              error={!!errors.scenes}
              helperText={errors.scenes?.[i]?.value?.message || ''}
              disabled={isReadonly}
            />
          </Grid>
        </Grid>
      ))}
    </Grid>
  )
}

const Hooks = () => {
  const {
    control,
    formState: { errors },
    setValue
  } = useFormContext<IUGCCampaign>()
  const { fields, append, remove, update } = useCustomFieldArray({ control, name: 'hooks', keyName: 'id' })
  const isReadonly = useReadonly('hooks')
  const strings = useStrings()
  const placeholders = [strings.campaign_creation_content_hooks_placeholder1]
  const pricing = usePricingContext()
  const currencySymbol = rootStore.brandStore.selectedBrand?.brandCountry ? getCurrencySymbolByCountry(rootStore.brandStore.selectedBrand?.brandCountry) : '€'

  const add = () => append({ id: `${fields.length}`, value: '' })

  useEffect(() => {
    if (!fields.length) add()
    setValue('numberOfHooks', fields.length)
  }, [JSON.stringify(fields)])

  return (
    <Grid item xs={12} mt={2}>
      {fields.map((field, i) => (
        <Grid container mt={2} alignItems='center' key={i}>
          <Grid item xs={11}>
            <TextField
              multiline
              value={field.value}
              onChange={e => update(i, { ...field, value: e.target.value })}
              label={strings.campaign_creation_content_hooks_placeh(i + 1)}
              type='outlined'
              fullWidth
              size='small'
              placeholder={placeholders[i] ?? strings.campaign_creation_content_hooks_placeh(i + 1)}
              InputLabelProps={{ shrink: true }}
              InputProps={{ startAdornment: <InputAdornment position='start'>{i + 1}.</InputAdornment> }}
              error={!!errors.hooks}
              helperText={errors.hooks?.[i]?.value?.message || ''}
              name={`hook${i + 1}`}
            />
          </Grid>
          {!isReadonly && fields.length > 1 && (
            <Grid item xs={1} textAlign='center'>
              <DeleteIcon fontSize='small' onClick={() => remove(i)} />
            </Grid>
          )}
        </Grid>
      ))}
      {!isReadonly && fields.length < 5 && (
        <Grid item xs={12} textAlign='center' mt={2}>
          <Button variant='outlined' onClick={add}>
            {strings.campaign_creation_content_hooks_add_button(currencySymbol, numberToEuro(pricing.hook))}
          </Button>
        </Grid>
      )}
    </Grid>
  )
}

const Ctas = () => {
  const {
    control,
    formState: { errors },
    setValue
  } = useFormContext<IUGCCampaign>()
  const { fields, append, remove, update } = useCustomFieldArray({ control, name: 'ctas', keyName: 'id' })
  const isReadonly = useReadonly('ctas')
  const strings = useStrings()
  const placeholders = [strings.campaign_creation_content_ctas_placeholder1]
  const pricing = usePricingContext()
  const currencySymbol = rootStore.brandStore.selectedBrand?.brandCountry ? getCurrencySymbolByCountry(rootStore.brandStore.selectedBrand?.brandCountry) : '€'

  const add = () => append({ id: `${fields.length}`, value: '' })

  useEffect(() => {
    if (!fields.length) add()
    setValue('numberOfCtas', fields.length)
  }, [JSON.stringify(fields)])

  return (
    <Grid item xs={12} mt={2}>
      {fields.map((field, i) => (
        <Grid container mt={2} alignItems='center' key={i}>
          <Grid item xs={11}>
            <TextField
              multiline
              value={field.value}
              onChange={e => update(i, { ...field, value: e.target.value })}
              label={strings.campaign_creation_content_ctas_placeh(i + 1)}
              type='outlined'
              fullWidth
              size='small'
              placeholder={placeholders[i] ?? strings.campaign_creation_content_ctas_placeh(i + 1)}
              InputLabelProps={{ shrink: true }}
              InputProps={{ startAdornment: <InputAdornment position='start'>{i + 1}.</InputAdornment> }}
              error={!!errors.ctas}
              helperText={errors.ctas?.[i]?.value?.message || ''}
              name={`cta${i + 1}`}
            />
          </Grid>
          {!isReadonly && fields.length > 1 && (
            <Grid item xs={1} textAlign='center'>
              <DeleteIcon fontSize='small' onClick={() => remove(i)} />
            </Grid>
          )}
        </Grid>
      ))}
      {!isReadonly && fields.length < 5 && (
        <Grid item xs={12} textAlign='center' mt={2}>
          <Button variant='outlined' onClick={add}>
            {strings.campaign_creation_content_ctas_add_button(currencySymbol, numberToEuro(pricing.cta))}
          </Button>
        </Grid>
      )}
    </Grid>
  )
}

const CampaignTask = () => {
  const { control, formState, trigger, getValues, setValue } = useFormContext<IUGCCampaign>()
  const [useMoodboard, setUseMoodboard] = useState(!!getValues('moodboard').length)
  const { fields, append, remove, update } = useCustomFieldArray({ control, name: 'mentions', keyName: 'id' })
  const isReadonly = useReadonly('mentions')
  const { type } = useParams<{ type: 'update' | 'view' }>()
  const pricing = usePricingContext()
  const strings = useStrings()
  const videoType = useWatch({ control, name: 'videoType' })
  const service = useWatch({ control, name: 'service' })
  const optionsService: IRadioCardGroupOption[] = [
    {
      value: Service.selfService,
      label: strings.campaign_creation_content_self_service_label,
      description: strings.campaign_creation_content_self_service_desc
    },
    {
      value: Service.nanoService,
      label: strings.campaign_creation_content_nano_storyboard_label,
      description: strings.campaign_creation_content_nano_storyboard_desc,
      price: pricing[Service.nanoService]
    }
  ].filterNotNull()

  const showService = videoType == VideoType.ad || videoType == VideoType.premium
  const showScenes = showService && (service == Service.selfService || (service == Service.nanoService && (type == 'update' || type == 'view')))
  const showMoodboard = !service || showScenes || type == 'update' || type == 'view'
  const isUnboxing = videoType === VideoType.unboxing

  const [mentionsTitle, mentionsDesc, mentionsLabel] = (() => {
    switch (videoType) {
      case VideoType.ad:
      case VideoType.premium:
      case VideoType.unboxing:
        return [strings.campaign_creation_content_mentions_title, strings.campaign_creation_content_mentions_desc, strings.campaign_creation_content_mentions_label] as const
    }
  })()

  useEffect(() => {
    if ((videoType == VideoType.premium || videoType == VideoType.ad) && type != 'update' && type != 'view') setValue('service', Service.selfService)
  }, [videoType])

  return (
    <Grid container>
      {showService && (
        <Grid item xs={12}>
          <ReactHookFormRadioCardGroup name='service' options={optionsService} md={6} />
        </Grid>
      )}

      {(showScenes || isUnboxing) && (
        <>
          {!isUnboxing && (
            <Fragment>
              <Grid item xs={12} mt={2}>
                <Typography variant='h5'>{strings.campaign_creation_content_hooks_title}</Typography>
              </Grid>
              <Hooks />
            </Fragment>
          )}

          <Grid item xs={12} mt={2}>
            <Typography variant='h5'>{strings.campaign_creation_content_scenes_title}</Typography>
          </Grid>
          <Grid item xs={12} mt={2}>
            <Typography variant='body2'>{strings.campaign_creation_content_scenes_desc}</Typography>
          </Grid>
          <Scenes />

          {!isUnboxing && (
            <Fragment>
              <Grid item xs={12} mt={2}>
                <Typography variant='h5'>{strings.campaign_creation_content_ctas_title}</Typography>
              </Grid>
              <Ctas />
            </Fragment>
          )}
        </>
      )}
      {!isUnboxing && (
        <Fragment>
          <Grid item xs={12} mt={showScenes || showService || isUnboxing ? 4 : 0}>
            <Typography variant='h5'>{mentionsTitle}</Typography>
          </Grid>
          <Grid item xs={12} mt={2}>
            <Typography variant='body2'>{mentionsDesc}</Typography>
          </Grid>
          <Grid item xs={12} mt={2}>
            {fields.map((field, i) => (
              <Grid container mt={2} alignItems='center' key={i}>
                <Grid item xs={11}>
                  <TextField
                    value={field.value}
                    onChange={e => {
                      update(i, { ...field, value: e.target.value })
                      // trigger only when necessary to avoid lags
                      if (e.target.value.length < 120 != (field.value || '').length < 120) trigger(`mentions.${i}`)
                    }}
                    label={mentionsLabel(i + 1)}
                    error={!!formState.errors.mentions?.[i]}
                    helperText={formState.errors.mentions?.[i]?.value?.message}
                    type='outlined'
                    fullWidth
                    size='small'
                    disabled={isReadonly}
                  />
                </Grid>
                {!isReadonly && fields.length > 1 && (
                  <Grid item xs={1} textAlign='center'>
                    <DeleteIcon fontSize='small' onClick={() => remove(i)} />
                  </Grid>
                )}
              </Grid>
            ))}
            {!isReadonly && fields.length < 5 && (
              <Grid item xs={12} textAlign='center' mt={2}>
                <Fab color='primary' aria-label='add' size='small' onClick={() => append({ id: `${fields.length}`, value: '' })}>
                  <AddIcon />
                </Fab>
              </Grid>
            )}
          </Grid>
        </Fragment>
      )}
      {showMoodboard && (
        <>
          <Grid item xs={12} mt={4}>
            <Typography variant='h5'>{strings.campaign_creation_content_moodboard_title}</Typography>
          </Grid>
          <Grid item xs={12} mt={2}>
            <Typography variant='body2'>{strings.campaign_creation_content_moodboard_body}</Typography>
          </Grid>
          <Grid item xs={12} mt={2}>
            <RadioGroup value={useMoodboard} onChange={e => setUseMoodboard(e.target.value == 'true')}>
              <FormControlLabel value={false} control={<Radio />} label={strings.campaign_creation_content_moodboard_dont_use} disabled={isReadonly} />
              <FormControlLabel value={true} control={<Radio />} label={strings.campaign_creation_content_moodboard_use} disabled={isReadonly} />
            </RadioGroup>
          </Grid>
          {useMoodboard && (
            <>
              <Grid item xs={12} mt={2}>
                <Typography variant='body2'>{strings.campaign_creation_content_moodboard_body1}</Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant='body2'>{strings.campaign_creation_content_moodboard_body2}</Typography>
              </Grid>
              <Grid item xs={12} mt={2}>
                <ReactHookFormDropzone name='moodboard' mode='append' accept='image/jpeg, image/png, video/mp4' />
              </Grid>
            </>
          )}
        </>
      )}
    </Grid>
  )
}

export default CampaignTask
