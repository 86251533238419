import { Alert, Grid, Typography } from '@mui/material'
import React from 'react'
import { Strings, useStrings } from '../../assets/localization/strings'
import { VideoFeature } from '../../models/GeneralTypes'
import { Pricing, usePricingContext } from '../../reusableUtils/pricing'
import ReactHookFormRadioCardGroupFullImage from '../common/form/ReactHookFormRadioCardGroupFullImage'
import { getVideoUrl } from '../../config'
import rootStore from '../../stores/rootStore'

const videoWithSubtitle = getVideoUrl('featureSubtitles', rootStore)
const videoWithLogo = getVideoUrl('featureLogo', rootStore)

export const getVideoFeatureOptions = (strings: Strings, pricing: Pricing, currentEdit: VideoFeature[] = []) => {
  return [
    {
      content: videoWithSubtitle,
      showControls: true,
      title: '',
      value: VideoFeature.small,
      description: strings.video_feature_small_description,
      price: pricing[VideoFeature.small],
      isPriceAdditional: true,
      addButton: true,
      disabled: currentEdit.includes(VideoFeature.small) || currentEdit.includes(VideoFeature.medium) || currentEdit.includes(VideoFeature.up_small_medium)
    },
    {
      content: videoWithLogo,
      showControls: true,
      title: '',
      value: currentEdit.includes(VideoFeature.small) ? VideoFeature.up_small_medium : VideoFeature.medium,
      description: strings.video_feature_medium_description,
      price: pricing[currentEdit.includes(VideoFeature.small) ? VideoFeature.up_small_medium : VideoFeature.medium],
      isPriceAdditional: true,
      addButton: true,
      topBadge: strings.most_popular,
      disabled: currentEdit.includes(VideoFeature.medium) || currentEdit.includes(VideoFeature.up_small_medium)
    }
  ]
}

const CampaignAdditionalFeatures = () => {
  const strings = useStrings()
  const pricing = usePricingContext()

  return (
    <Grid container>
      <Grid item xs={12} mt={2}>
        <Typography variant='h5'>{strings.campaign_creation_video_features_title}</Typography>
      </Grid>
      <Grid item xs={12} mt={2}>
        <Alert severity='info'>{strings.campaign_creation_video_features_info}</Alert>
      </Grid>
      <Grid item xs={12} mt={2}>
        <ReactHookFormRadioCardGroupFullImage name='videoFeature' options={getVideoFeatureOptions(strings, pricing).filterNotNull()} required={false} />
      </Grid>
    </Grid>
  )
}

export default CampaignAdditionalFeatures
