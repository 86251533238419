import ArrowCircleLeftIcon from '@mui/icons-material/ArrowCircleLeft'
import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight'
import CircleIcon from '@mui/icons-material/Circle'
import { Fade, Grid, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import React, { useEffect, useState } from 'react'
import background from '../../assets/backgrounds/bg_signup.svg'
import intro_1 from '../../assets/images/intro_1.png'
import intro_2 from '../../assets/images/intro_2.png'
import intro_3 from '../../assets/images/intro_3.png'
import intro_4 from '../../assets/images/intro_4.png'
import intro_1_en from '../../assets/images/intro_1_en.png'
import intro_2_en from '../../assets/images/intro_2_en.png'
import intro_3_en from '../../assets/images/intro_3_en.png'
import intro_4_en from '../../assets/images/intro_4_en.png'
import { useStrings, StringsContext } from '../../assets/localization/strings'
import { color } from '../../theme'
import { useContext } from 'react'

const useStyles = makeStyles(() => ({
  instructionContainer: {
    height: '100vh',
    padding: '0 32px'
  },
  background: {
    backgroundImage: `url(${background})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center'
  },
  forBrands: {
    position: 'absolute',
    top: '24px',
    right: '24px'
  },
  imageItem: {
    paddingRight: '8px'
  },
  instructionText: {
    height: '100%'
  },
  brandLink: {
    paddingTop: '8px'
  }
}))

const Instructions = () => {
  const strings = useStrings()
  const { language } = useContext(StringsContext)
  const classes = useStyles()

  // Defining language-specific image sets
  const defaultImages = [intro_1, intro_2, intro_3, intro_4]
  const englishImages = [intro_1_en, intro_2_en, intro_3_en, intro_4_en]

  // Determining which image set to use based on language
  const images = language.startsWith('en') ? englishImages : defaultImages

  const titles = [strings.login_slide_title1, strings.login_slide_title2, strings.login_slide_title3, strings.login_slide_title4]
  const descriptions = [strings.login_slide_desc1, strings.login_slide_desc2, strings.login_slide_desc3, strings.login_slide_desc4]

  const [selectedImage, setSelectedImage] = useState(0)
  const [fadeIn, setFadeIn] = useState(true)
  const [circleClicked, setCircleClicked] = useState(false)

  useEffect(() => {
    if (!circleClicked) {
      let timer = setTimeout(() => rightClick(), 5000)
      return () => {
        clearTimeout(timer)
      }
    }
  }, [selectedImage, circleClicked])

  const leftClick = () => {
    setFadeIn(false)
    setTimeout(() => {
      const copy = selectedImage - 1
      if (copy < 0) {
        setSelectedImage(2)
      } else {
        setSelectedImage(copy)
      }
      setFadeIn(true)
    }, 500)
  }

  const rightClick = () => {
    setFadeIn(false)
    setTimeout(() => {
      const copy = selectedImage + 1
      if (copy > images.length - 1) {
        setSelectedImage(0)
      } else {
        setSelectedImage(copy)
      }
      setFadeIn(true)
    }, 500)
  }

  const onCircleClicked = (index: number) => {
    setFadeIn(false)
    setTimeout(() => {
      setCircleClicked(true)
      setSelectedImage(index)
      setCircleClicked(false)
      setFadeIn(true)
    }, 500)
  }

  return (
    <Grid item xs={6} className={classes.background}>
      <Grid container className={classes.instructionContainer} alignItems='center' justifyContent='center'>
        <Grid item container alignItems='center' justifyContent='center'>
          <Grid item xs={1} textAlign='center'>
            <ArrowCircleLeftIcon onClick={() => leftClick()} sx={{ color: color.white }} />
          </Grid>
          <Grid item xs={10} textAlign='center'>
            <Fade in={fadeIn}>
              <img src={images[selectedImage]} width='100%' alt={`Slide ${selectedImage + 1}`} />
            </Fade>
          </Grid>
          <Grid item xs={1} textAlign='center'>
            <ArrowCircleRightIcon onClick={() => rightClick()} sx={{ color: color.white }} />
          </Grid>
          <Grid item container justifyContent='center' pt={2}>
            {images.map((img, i) => (
              <CircleIcon
                key={img + i}
                sx={{ color: color.white, opacity: selectedImage === i ? 1 : 0.6, fontSize: '8px', marginRight: '8px' }}
                onClick={() => onCircleClicked(i)}
              />
            ))}
          </Grid>
          <Grid item xs={8} container pt={4}>
            <Grid item xs={12}>
              <Typography variant='h2' textAlign='center'>
                {titles[selectedImage]}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant='body2' pt={2} textAlign='center'>
                {descriptions[selectedImage]}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default Instructions
