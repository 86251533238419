import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Hidden,
  IconButton,
  Stack,
  Typography,
  useMediaQuery
} from '@mui/material'
import React, { useEffect, useState } from 'react'
import { FormProvider, useWatch } from 'react-hook-form'

import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos'
import ArrowDownIcon from '@mui/icons-material/ArrowDownward'
import ArrowUpIcon from '@mui/icons-material/ArrowUpward'
import ErrorIcon from '@mui/icons-material/Error'
import ReportIcon from '@mui/icons-material/Report'
import { LoadingButton } from '@mui/lab'
import { observer } from 'mobx-react'
import moment from 'moment'
import { useSnackbar } from 'notistack'
import { useHistory, useParams } from 'react-router-dom'
import { useStrings } from '../../assets/localization/strings'
import { CampaignGender, ProductType, Service, StylinkGender, VideoType } from '../../models/GeneralTypes'
import { numberToEuro } from '../../reusableUtils/Helpers'
import { PricingContext, usePricing } from '../../reusableUtils/pricing'
import { ReadonlyContext } from '../../reusableUtils/useReadonly'
import { FocusableSections, Sections, useSections } from '../../reusableUtils/useSections'
import rootStore from '../../stores/rootStore'
import { color, theme } from '../../theme'
import { MockView } from '../common/MockView'
import PaymentModal from '../common/PaymentModal'
import BrandInfo, { BrandInfoJustLogo } from '../createBrand/BrandInfo'
import CampaignAdditionalFeatures from './CampaignAdditionalFeatures'
import CampaignAudience from './CampaignAudience'
import CampaignBudget from './CampaignBudget'
import CampaignDisclaimer from './CampaignDisclaimer'
import CampaignProduct from './CampaignProduct'
import CampaignTask from './CampaignTask'
import CampaignType from './CampaignType'
import { PricingFormContext, PricingInUGCForm, usePricingFormByContext } from './Pricing'
import usePersistedUGCForm, { IUGCCampaign, ageOptionToObj } from './usePersistedUGCForm'
import CampaignImages from './CampaignImages'
import { getCurrencySymbolByCountry } from '../../reusableUtils/currency'

export type { IUGCCampaign }

export type Creator = Awaited<ReturnType<typeof rootStore.campaignStore.getAllFinishedCreators>>[number]
const mobilePricingHeight = '40vh'
const UGCCampaignForm = observer(() => {
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))
  const { enqueueSnackbar } = useSnackbar()
  const history = useHistory()
  const [showMobilePricing, setShowMobilePricing] = (() => {
    const [showMobilePricing, setShowMobilePricing] = useState(false)
    return [isMobile && showMobilePricing, setShowMobilePricing]
  })()

  const [showPaymentModal, setShowPaymentModal] = useState(false)
  const [loading, setLoading] = useState(false)
  const [submitLoading, setSubmitLoading] = useState(false)

  const { selectedBrand } = rootStore.brandStore
  const { brandId, campaignId, type } = useParams<{ brandId: string; campaignId: string; type: 'update' | 'duplicate' | 'view'; couponCode?: string }>()
  const isAgency = selectedBrand?.isAgency ?? false
  const isCreation = !campaignId
  const id = type == 'duplicate' ? `${brandId}_${campaignId}_duplicate` : isCreation ? brandId : `${brandId}_${campaignId}`
  const { dialogMethods, methods, loadIntoForm, getUgcObjectFromForm, compareWithPersistenceAndAsk } = usePersistedUGCForm(id, isAgency, !selectedBrand?.brandLogo)
  const { pricing } = usePricing()
  const [brand, setBrand] = useState<any>()
  const strings = useStrings()
  const pricingForm = usePricingFormByContext(methods.control, showPaymentModal ? brand : undefined)

  const [creators, setCreators] = (() => {
    const [creators, setCreators] = useState<Creator[]>()
    const gender = useWatch({ control: methods.control, name: 'gender' })
    const ageOptions = useWatch({ control: methods.control, name: 'ageRanges' })
    const filtered = Array.isArray(creators)
      ? creators.filter(
          c =>
            (`${gender}` == CampaignGender.ANY || `${gender}` == c.gender) &&
            ageOptions.find(o => {
              const age = moment().diff(c.birth_date, 'y', true)
              return ageOptionToObj[o].min <= age && age <= ageOptionToObj[o].max
            })
        )
      : creators
    return [filtered, setCreators]
  })()

  const isCreating = !!(brandId && campaignId)
  const isReadonly = type == 'update' || type == 'view'
  const isUnboxing = methods.getValues('videoType') === VideoType.unboxing

  const { sections, viewRefs, setViewRefs, lastVisible } = useSections([
    [() => <CampaignType />, strings.campaign_creation_section_video_type],
    !isUnboxing ? undefined : [() => <CampaignBudget />, strings.campaign_creation_section_budget],
    [() => <CampaignTask />, strings.campaign_creation_section_content],
    isUnboxing ? undefined : [() => <CampaignAdditionalFeatures />, undefined],
    isUnboxing ? undefined : [() => <CampaignBudget />, strings.campaign_creation_section_budget],
    [() => <CampaignImages isReadOnly= {isReadonly} />, strings.campaign_creation_section_budget],
    [() => <CampaignAudience />, strings.campaign_creation_section_requirements],
    // !isReadonly && Array.isArray(creators) && creators.length
    //   ? [() => <InviteCreators creators={creators} loadCreators={loadCreators} />, strings.campaign_creation_section_invite]
    //   : undefined,
    [() => <CampaignProduct />, strings.campaign_creation_section_product],
    isAgency || !selectedBrand?.brandLogo ? [isAgency ? () => <BrandInfo campaignForm={true} /> : () => <BrandInfoJustLogo />, strings.campaign_creation_section_brand] : null,
    isReadonly ? undefined : [() => <CampaignDisclaimer />, undefined]
  ])

  useEffect(() => {
    checkIfUpdate()
  }, [])

  useEffect(() => {
    pricingForm.data.brand = brand
  }, [brand])

  const goToDashboard = () => history.push(`/${brandId}/dashboard`)
  const checkIfUpdate = async () => {
    if (isCreating) {
      if (type != 'update' && type != 'duplicate' && type != 'view') {
        goToDashboard()
        return
      }

      setLoading(true)

      try {
        const campaign = await rootStore.campaignStore.getUgcCampaign(campaignId, brandId)

        if (!campaign) {
          goToDashboard()
          return
        }

        await loadIntoForm(campaign)
      } catch (e) {
        console.error(e)
        enqueueSnackbar(strings.error, { variant: 'error' })
        goToDashboard()
      } finally {
        setLoading(false)
      }
    }
    loadCreators()
    if (type != 'view') compareWithPersistenceAndAsk()
  }

  // This handles also if it is an update
  const onSubmit = async () => {
    if (!selectedBrand) {
      enqueueSnackbar(strings.error, { variant: 'error' })
      return
    }
    try {
      setSubmitLoading(true)
      let goNext = goToDashboard
      const ugcCampaign = await getUgcObjectFromForm(selectedBrand, pricingForm.couponCode)
      ugcCampaign.numberOfVideos = +ugcCampaign.numberOfVideos
      if (type === 'update') {
        await rootStore.campaignStore.updateCampaignUGC({ ...ugcCampaign, moodboard: ugcCampaign.moodboard ?? [], campaignId })
      } else {
        if (methods.getValues('service') != Service.selfService && methods.getValues('videoType') == VideoType.ad) ugcCampaign.scenes = []
        if (selectedBrand && !selectedBrand.brandLogo && !selectedBrand.isAgency) selectedBrand.brandLogo = ugcCampaign.brandLogo
        await rootStore.campaignStore.createUgcCampaign(ugcCampaign)
      }
      dialogMethods.deletePersistence()
      goNext()
    } catch (e) {
      console.log(e)
      enqueueSnackbar(strings.error, { variant: 'error' })
    } finally {
      setSubmitLoading(false)
    }
  }
  const loadCreators = async () => {
    if (isReadonly) return
    setCreators(undefined)
    try {
      const finishedContent = await rootStore.campaignStore.getAllFinishedCreators()
      setCreators(finishedContent)
    } catch (e) {
      setCreators(e)
    }
  }

  if (pricing == null)
    return (
      <Grid container justifyContent='center'>
        <CircularProgress />
      </Grid>
    )
  if (pricing instanceof Error) return <>{strings.error}</>

  const renderPricingPortal = () => <PricingInUGCForm mobile={isMobile} />

  const hasErrors = Object.keys(methods.formState.errors).length > 0
  const fields: (keyof IUGCCampaign)[] = ['videoType', 'unboxingType', 'videoDuration', 'videoFeature', 'numberOfVideos', 'service', 'hooks', 'ctas', 'productUrls']
  const price = pricingForm.invoice ? (
    pricingForm.invoice instanceof Error ? (
      <IconButton onClick={() => pricingForm.load(0)}>
        <ErrorIcon />
      </IconButton>
    ) : (
      numberToEuro(pricingForm.invoice.subtotal)
    )
  ) : (
    <CircularProgress size={20} sx={{ mr: 1 }} />
  )

  const currencySymbol = rootStore.brandStore.selectedBrand?.brandCountry ? getCurrencySymbolByCountry(rootStore.brandStore.selectedBrand?.brandCountry) : '€'
  return (
    <ReadonlyContext.Provider value={{ mode: type == 'update' ? 'some' : type == 'view' ? 'all' : 'none', names: type == 'update' ? fields : undefined }}>
      <PricingFormContext.Provider value={pricingForm}>
        <PricingContext.Provider value={pricing}>
          <MockView name='Create Campaign'>
            <Grid container>
              <Grid item xs={4}>
                <Button
                  onClick={() => {
                    const empty = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAAAAAA6fptVAAAACklEQVQYV2P4DwABAQEAWk1v8QAAAABJRU5ErkJggg=='
                    methods.setValue('name', 'infs')
                    methods.setValue('price', 200)
                    methods.setValue('campaignImage', empty)
                    methods.setValue('link', 'google.com')
                    methods.setValue('description', 'desc')
                    methods.setValue('productType', ProductType.physical)

                    methods.setValue('brandName', 'brand infs')
                    methods.setValue('brandLogo', empty)

                    methods.setValue('scenes', [{ id: 'id1', value: 'sc1' }])
                    methods.setValue('informationCheck', true)
                    methods.setValue('contactCheck', true)
                  }}>
                  Fill out
                </Button>
              </Grid>
              <Grid item xs={4}>
                <Button
                  onClick={() => {
                    const link = 'https://s3.eu-central-1.amazonaws.com/com.freebeeapp/dog.mp4'
                    setCreators([
                      { birth_date: moment().add(-20, 'y').toDate(), gender: StylinkGender.DIVERSE, link, first_name: 'name1', influencerId: 'id1' },
                      { birth_date: moment().add(-20, 'y').toDate(), gender: StylinkGender.MALE, link, first_name: 'name2', influencerId: 'id2' },
                      { birth_date: moment().add(-20, 'y').toDate(), gender: StylinkGender.FEMALE, link, first_name: 'name3', influencerId: 'id3' }
                    ])
                  }}>
                  Set Creators
                </Button>
              </Grid>
              <Grid item xs={4}>
                <Button onClick={() => setCreators([])}>Clear Creators</Button>
              </Grid>
              <Grid item xs={4}>
                <Button onClick={() => setCreators(undefined)}>Set Creators Loading</Button>
              </Grid>
              <Grid item xs={4}>
                {methods.getValues('inviteInfluencers').join(', ')}
              </Grid>
              <Grid item xs={4}>
                {methods.getValues('videoFeature')}
              </Grid>
              <Grid item xs={4}>
                Locale {methods.getValues('locale')}
              </Grid>
            </Grid>
          </MockView>
          <PersistenceDialog {...dialogMethods} />
          {!isReadonly && (
            <PaymentModal
              open={showPaymentModal}
              handleClose={() => {
                pricingForm.ignoreLoad.current = true
                setShowPaymentModal(false)
              }}
              campaignForm={methods}
              paymentType='UGC_CAMPAIGN'
              setBrand={setBrand}
              callback={onSubmit}
            />
          )}
          <FormProvider {...methods}>
            <form
              onSubmit={methods.handleSubmit(() => {
                if (hasErrors) return
                if (type == 'update') {
                  onSubmit()
                  return
                }
                pricingForm.ignoreLoad.current = true
                setShowPaymentModal(true)
              })}>
              <Grid sx={{ height: 'calc(100vh - 112px)', position: 'relative', background: '#FDFDFD' }}>
                <Hidden lgDown>
                  <Grid sx={{ height: '100%', width: '220px', position: 'absolute', top: 0, left: 0, overflowX: 'hidden', borderRight: '1px solid #e0e0e0' }}>
                    <Grid container direction='column' alignItems='center' justifyContent='center' style={{ height: '100%' }}>
                      <Grid item xs={3}>
                        <Sections sections={sections} viewRefs={viewRefs} lastVisible={lastVisible} />
                      </Grid>
                    </Grid>
                  </Grid>
                </Hidden>
                <Grid
                  sx={{
                    height: '100%',
                    overflowX: 'scroll',
                    paddingBottom: '50px',
                    maxWidth: '900px',
                    marginLeft: '260px',
                    marginRight: '350px',
                    [theme.breakpoints.down('lg')]: { marginLeft: 0 },
                    [theme.breakpoints.down('md')]: { marginRight: 0 },
                    [theme.breakpoints.up('sm')]: { padding: '25px' },
                    textAlign: loading ? 'center' : 'start',
                    pb: showMobilePricing ? mobilePricingHeight : undefined
                  }}>
                  {loading ? (
                    <CircularProgress />
                  ) : (
                    <Grid container direction='column' spacing={3} mt={'48px'}>
                      {rootStore.brandStore.selectedBrand?.brandId && (
                        <Grid item xs={12}>
                          <IconButton onClick={goToDashboard}>
                            <ArrowBackIosIcon fontSize='small' />
                            <Typography ml={1} variant='body2'>
                              {strings.back}
                            </Typography>
                          </IconButton>
                        </Grid>
                      )}
                      <FocusableSections sections={sections} setViewRefs={setViewRefs} />
                    </Grid>
                  )}
                </Grid>
                {!isReadonly && !showPaymentModal && !isMobile && (
                  <Grid sx={{ height: '100%', width: '400px', position: 'absolute', top: 0, right: 0, overflowX: 'hidden', padding: '0px 25px', paddingTop: '97px' }}>
                    {renderPricingPortal()}
                  </Grid>
                )}
                <Stack
                  sx={{
                    width: '100%',
                    position: 'fixed',
                    bottom: 0,
                    left: 0,
                    overflowX: 'hidden',
                    zIndex: 10,
                    backgroundColor: color.white,
                    padding: '5px',
                    borderTop: '1px solid #e0e0e0',
                    maxHeight: mobilePricingHeight
                  }}>
                  {showMobilePricing && !showPaymentModal && renderPricingPortal()}
                  <Box pb={5} />
                  <Stack
                    direction='row'
                    alignItems='center'
                    sx={{ width: '100%', position: 'fixed', bottom: 0, left: 0, backgroundColor: color.white, padding: '5px', borderTop: '1px solid #e0e0e0', zIndex: 5 }}>
                    {isMobile && <IconButton onClick={() => setShowMobilePricing(p => !p)}>{showMobilePricing ? <ArrowDownIcon /> : <ArrowUpIcon />}</IconButton>}
                    {isMobile && (
                      <>
                        {typeof price != 'string' && <Typography component='div' variant='h5' whiteSpace='nowrap' sx={{ mr: 1 }}>{`${currencySymbol} `}</Typography>}
                        {typeof price == 'string' ? <Typography component='div' variant='h5' whiteSpace='nowrap' sx={{ mr: 1 }}>{`${currencySymbol} ${price}`}</Typography> : price}
                      </>
                    )}
                    <Box mr='auto' />
                    {hasErrors && (
                      <>
                        <ReportIcon fontSize='small' color='error' />
                        <Typography variant='caption' color='error' pl={1} pr={2}>
                          {strings.campaign_creation_error}
                        </Typography>
                      </>
                    )}
                    {type != 'view' && (
                      <>
                        <LoadingButton loading={submitLoading} variant='contained' type='submit' id={type == 'update' ? 'UPDATE_CAMPAIGN_FORM' : 'CREATE_CAMPAIGN_FORM'}>
                          {type == 'update' ? strings.campaign_creation_update : strings.campaign_creation_create}
                        </LoadingButton>
                      </>
                    )}
                  </Stack>
                </Stack>
              </Grid>
            </form>
          </FormProvider>
        </PricingContext.Provider>
      </PricingFormContext.Provider>
    </ReadonlyContext.Provider>
  )
})

type PersistenceDialogConfig = ReturnType<typeof usePersistedUGCForm>['dialogMethods']
const PersistenceDialog = ({ showPersistDialog, setDataFromPersistence, closePersistDialog, deletePersistence }: PersistenceDialogConfig) => {
  const strings = useStrings()
  return (
    <Dialog open={showPersistDialog} onClose={closePersistDialog}>
      <DialogTitle>{strings.campaign_creation_persist_title}</DialogTitle>
      <DialogContent>
        <DialogContentText>{strings.campaign_creation_persist_body}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button sx={{ background: '#000', color: 'white' }} onClick={setDataFromPersistence}>
          {strings.yes}
        </Button>
        <Button onClick={deletePersistence}>{strings.campaign_creation_persist_delete}</Button>
      </DialogActions>
    </Dialog>
  )
}
export default UGCCampaignForm
