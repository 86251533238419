import { Grid, Typography, TextField, Fab, Box, Select } from '@mui/material'
import React from 'react'
import { useFormContext, useWatch } from 'react-hook-form'
import { useStrings } from '../../assets/localization/strings'
import ReactHookFormSelect, { IOption } from '../common/form/ReactHookFormSelect'
import { IUGCCampaign } from './UGCCampaignForm'
import AddIcon from '@mui/icons-material/Add'
import { PhotoFormat } from '../../models/GeneralTypes'

const formatOptions: IOption[] = Object.entries(PhotoFormat).map(([key, value]) => ({
  value: value,
  label: `${key} (${value})`
}))

const CampaignImages = ({ isReadOnly }) => {
  const { control, setValue, watch } = useFormContext<IUGCCampaign>()
  const numberOfPhotos = useWatch({ control, name: 'numberOfPhotos' })
  const photos = useWatch({ control, name: 'photos' }) || 0
  const strings = useStrings()

  React.useEffect(() => {
    const photoCount = Number(numberOfPhotos) || 0
    const currentPhotos = photos || []

    if (currentPhotos.length < photoCount) {
      const newPhotos = [...currentPhotos]
      for (let i = currentPhotos.length; i < photoCount; i++) {
        newPhotos.push({ description: '', format: PhotoFormat.Cinematic })
      }
      setValue('photos', newPhotos)
    }
    else if (currentPhotos.length > photoCount) {
      setValue('photos', currentPhotos.slice(0, photoCount))
    }
  }, [numberOfPhotos, setValue])

  const handleAddPhoto = () => {
    const newCount = Number(numberOfPhotos) + 1
    setValue('numberOfPhotos', newCount)
    // Add a new photo object to the photos array
    const newPhotos = photos ? [...photos] : []
    newPhotos.push({ description: '', format: PhotoFormat.Square })
    setValue('photos', newPhotos)
  }

  const renderPhotoInputs = () => {
    const photoCount = Number(numberOfPhotos) || 0
    const inputs = []

    return Array.from({ length: photoCount }, (_, i) => (
      <Grid container spacing={2} key={i} sx={{ mt: 1 }}>
        <Grid item xs={12} md={8}>
          <TextField
            multiline
            size='small'
            fullWidth
            name={`photos.${i}.description`}
            variant='outlined'
            value={photos[i]?.description || ''}
            label={`${strings.campaign_photo_label} ${i + 1}`}
            onChange={e => {
              if (Array.isArray(photos)) {
                const newPhotos = [...photos]
                newPhotos[i] = { ...newPhotos[i], description: e.target.value }
                setValue('photos', newPhotos)
              }
            }}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <ReactHookFormSelect
            name={`photos.${i}.format`}
            label='Format'
            options={formatOptions.map(c => ({ value: c.value, label: c.label }))}
          />
        </Grid>
      </Grid>
    ))
  }

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Typography variant='h4'>{strings.campaign_order_photos_title}</Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant='body2'>{strings.campaign_order_photos_description}</Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant='body2'>{strings.campaign_order_photos_question}</Typography>
      </Grid>
      <Grid item xs={12} sm={4} sx={{ my: 'auto' }}>
        <ReactHookFormSelect
          name='numberOfPhotos'
          label={strings.campaign_photos_label}
          options={Array.from({ length: 6 }, (_, i) => ({ value: String(i), label: String(i) }))}
          disabled={isReadOnly}
        />
      </Grid>
      <Grid item xs={12} sm={8}>
        <Box
          sx={{
            display: 'flex',
            gap: 1,
            height: '100%',
            alignItems: 'end'
          }}>
          <Box
            component='img'
            src={'https://s3.eu-central-1.amazonaws.com/com.freebeeapp/public/Bild (1).jpeg'}
            alt='Campaign Image 1'
            sx={{
              width: '120px',
              height: '120px',
              objectFit: 'cover',
              borderRadius: '8px'
            }}
          />
          <Box
            component='img'
            src={'https://s3.eu-central-1.amazonaws.com/com.freebeeapp/public/Bild (4).jpeg'}
            alt='Campaign Image 2'
            sx={{
              width: '200px',
              height: '80%',
              objectFit: 'cover',
              borderRadius: '8px'
            }}
          />
          <Box
            component='img'
            src={'https://s3.eu-central-1.amazonaws.com/com.freebeeapp/public/Bild+(2).jpeg'}
            alt='Campaign Image 3'
            sx={{
              width: '120px',
              height: '200px',
              objectFit: 'cover',
              borderRadius: '8px'
            }}
          />
        </Box>
      </Grid>
      {/* Photo description and format inputs */}
      <Grid item xs={12}>
        {numberOfPhotos > 0 && (
          <Grid item xs={12} sx={{ mt: 4 }}>
            <Typography variant='body2'>{strings.campaign_order_photos_text_field}</Typography>
          </Grid>
        )}
        {renderPhotoInputs()}
      </Grid>
      {numberOfPhotos < 5 &&
        numberOfPhotos > 0 &&
        !isReadOnly &&(
          <Grid item xs={12} textAlign='center' mt={2}>
            <Fab color='primary' aria-label='add' size='small' onClick={handleAddPhoto}>
              <AddIcon />
            </Fab>
          </Grid>
        )}
    </Grid>
  )
}

export default CampaignImages
