import { CircularProgress, Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useStrings } from '../../assets/localization/strings'
import rootStore from '../../stores/rootStore'
import styling from '../../style/campaign-details.module.css'
import styles from '../../style/CampaignDashboard.module.css'
import moment from 'moment'
import { getCurrencySymbolByCode } from '../../reusableUtils/currency'

type Invoice = Awaited<ReturnType<typeof rootStore.brandStore.getInvoices>>[number]

const Invoices = () => {
  const [loading, setLoading] = useState(true)
  const [brandName] = useState('')
  const [invoices, setInvoices] = useState<Invoice[]>([])
  const [errorCode, setErrorCode] = useState<string>()
  const strings = useStrings()

  useEffect(() => {
    const onMount = async () => {
      try {
        setInvoices(await rootStore.brandStore.getInvoices())
      } catch (e) {
        if (e.response) setErrorCode(e.response.body.errorMessage)
      } finally {
        setLoading(false)
      }
    }
    onMount()
  }, [])

  const getDate = (invoice: Invoice) => {
    if (invoice.status === 'open' && invoice.status_transitions.finalized_at) {
      const date = new Date(invoice.status_transitions.finalized_at * 1000)
      return `${date.getDate()}.${date.getMonth() + 1}.${date.getFullYear()}`
    }
    if (invoice.status === 'paid' && invoice.status_transitions.paid_at) {
      const date = new Date(invoice.status_transitions.paid_at * 1000)
      return `${date.getDate()}.${date.getMonth() + 1}.${date.getFullYear()}`
    }
    return strings.brand_invoices_missing_date
  }

  const renderTable = () => {
    if (errorCode) {
      console.log('HERE', JSON.stringify(errorCode))
      if (errorCode === '[400] unauthorized')
        return (
          <div>
            <b>{strings.brand_invoices_no_auth}</b>
          </div>
        )

      return <div>{strings.brand_invoices_error}</div>
    }

    if (!invoices.length) return <div>{strings.brand_invoices_empty}</div>

    return (
      <TableContainer component={Paper}>
        <Table className={styles.table} aria-label='simple table'>
          <TableHead>
            <TableRow>
              <TableCell>
                <b>{strings.brand_invoices_number}</b>
              </TableCell>
              <TableCell className={styles.statusColumn}>
                <b>{strings.brand_invoices_table_date}</b>
              </TableCell>
              <TableCell>
                <b>{strings.brand_invoices_table_type}</b>
              </TableCell>
              <TableCell>
                <b>{strings.brand_invoices_table_sum}</b>
              </TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {invoices.map(invoice => {
              const currencySymbol = getCurrencySymbolByCode(invoice.currency)
              return (
                <>
                  <TableRow key={invoice.id}>
                    <TableCell colSpan={1} component='td'>
                      {invoice.number}
                    </TableCell>
                    <TableCell colSpan={1} component='td'>
                      {getDate(invoice)}
                    </TableCell>
                    <TableCell colSpan={1} component='td'>
                      {strings.brand_invoices_debit}
                    </TableCell>
                    <TableCell colSpan={1}>
                      {currencySymbol} {invoice.total < 100 ? 0 : `${invoice.total}`.slice(0, -2)}
                      {invoice.total !== 0 ? ',' : ''}
                      {invoice.total !== 0 ? `${invoice.total}`.slice(-2) : ''}
                    </TableCell>
                    <TableCell colSpan={1}>
                      {invoice.invoice_pdf ? (
                        <a href={invoice.invoice_pdf} target='_blank' rel='noopener noreferrer' style={{ color: '#a890fe' }}>
                          {strings.brand_invoices_download}
                        </a>
                      ) : (
                        strings.brand_invoices_download_error
                      )}
                    </TableCell>
                  </TableRow>
                  {invoice.creditNotes.map(creditNote => (
                    <TableRow key={invoice.id}>
                      <TableCell colSpan={1} component='td'>
                        {creditNote.number}
                      </TableCell>
                      <TableCell colSpan={1} component='td'>
                        {moment(creditNote.created * 100).format('DD.MM.YYYY')}
                      </TableCell>
                      <TableCell colSpan={1} component='td'>
                        {strings.brand_invoices_credit}
                      </TableCell>
                      <TableCell colSpan={1}>
                        {currencySymbol} {creditNote.total < 100 ? 0 : `${creditNote.total}`.slice(0, -2)}
                        {creditNote.total !== 0 ? ',' : ''}
                        {creditNote.total !== 0 ? `${creditNote.total}`.slice(-2) : ''}
                      </TableCell>
                      <TableCell colSpan={1}>
                        {creditNote.pdf ? (
                          <a href={creditNote.pdf} target='_blank' rel='noopener noreferrer' style={{ color: '#a890fe' }}>
                            {strings.brand_invoices_download}
                          </a>
                        ) : (
                          strings.brand_invoices_download_error
                        )}
                      </TableCell>
                    </TableRow>
                  ))}
                </>
              )
            })}
          </TableBody>
        </Table>
      </TableContainer>
    )
  }

  if (loading)
    return (
      <div className='loadingFreebee'>
        <CircularProgress className='loadingFreebee' color='primary' />
      </div>
    )

  return (
    <Grid container>
      <Grid item xs={12}>
        <h2>{brandName}</h2>
      </Grid>
      <Grid className={styling.detailsContainer} container>
        {renderTable()}
      </Grid>
    </Grid>
  )
}
export default Invoices
