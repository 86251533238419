import { Box, IconButton, Paper, Stack, Typography } from '@mui/material'
import React from 'react'
import styles from '../../style/contentCampaign/content.module.css'
import { color } from '../../theme'

export const ImageViewer = ({ src, children, height = 340, width = 192 }: { src?: string; children?: JSX.Element; height?: number; width?: number }) => {
  const borderRadius = '20px'
  const p = 0.5

  return (
    <Box width={`${width + 8}px`} height={`${height}px`} position='relative'>
      <Paper elevation={5} sx={{ borderRadius, p, position: 'absolute' }}>
        <Box
          sx={{
            width: `${width}px`,
            height: `${height}px`,
            borderRadius,
            overflow: 'hidden',
            position: 'relative'
          }}>
          <img
            src={src}
            alt='Content'
            style={{
              width: '100%',
              height: '100%',
              objectFit: 'cover',
              borderRadius
            }}
          />
        </Box>
      </Paper>

      <Stack position='absolute' width='inherit' height={`${height + p * 2 * 8}px`} sx={{ pointerEvents: 'none' }}>
        <Box sx={{ pointerEvents: 'auto' }}>{children}</Box>
      </Stack>
    </Box>
  )
}

export const PlayerBottomText = ({ name, icon, onClick }: { name: string; icon?: string; onClick?(): void }) => (
  <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%' }}>
    <Stack
      onClick={onClick}
      direction='row'
      alignItems='center'
      justifyContent='start'
      sx={{
        borderRadius: 1,
        p: 1,
        borderEndEndRadius: 0,
        borderEndStartRadius: 0,
        position: 'absolute',
        maxWidth: '70%',
        bottom: 0,
        background: color.white
      }}>
      <img className={styles.infoProfilePicture} src={icon} />
      <Typography textOverflow='ellipsis' whiteSpace='nowrap' overflow='hidden'>
        {name}
      </Typography>
    </Stack>
  </Box>
)
